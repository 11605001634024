import { keepPreviousData, queryOptions } from '@tanstack/react-query';
import { BaseLayerConfig, LayerConfig, UnitPreferences } from 'daydash-data-structures';
import { isEqualWith } from 'lodash';
import { Store, authAtoms } from 'src/state';
import { DataFetchOrder } from './dataFetching';
import {
  CORE_CLIENT_SEND_CHANNEL,
  CORE_CLIENT_RESPONSE_CHANNEL,
  CoreClientDirectRPCCalls,
  CoreRPCCalls,
  DBQueries
} from './types';
import CommonRpcSender from './commonRpc/commonRpcSender';
import CommonRpcReceiver from './commonRpc/commonRpcReceiver';
import { RPCCancelError } from './commonRpc/types';

type DBFetchCall = keyof DBQueries;

export default class CoreClient implements CoreClientDirectRPCCalls {
  constructor(private store: Store) {}

  coreSenderRPC = CommonRpcSender.createFromBroadcastChannels<CoreRPCCalls>(
    new BroadcastChannel(CORE_CLIENT_SEND_CHANNEL),
    new BroadcastChannel(CORE_CLIENT_RESPONSE_CHANNEL)
  );

  getCapabilities = async () => {
    return this.coreSenderRPC?.call('getCapabilities') as Promise<string[]>;
  };

  public initialized = false;

  initialize = async () => {
    const worker = new Worker(new URL('./core.worker.ts', import.meta.url), {
      type: 'module'
    });
    /**
     * Used for when the core instance needs to call the main thread for something
     */
    const coreDirectReceiverRPC = CommonRpcReceiver.createFromWorker<CoreClientDirectRPCCalls>(
      worker,
      this
    );
    this.coreSenderRPC.initialize();
    coreDirectReceiverRPC.initialize();
    // Wait for broadcastedRPC to connect. We don't need to worry about the direct RPC since it may not be used
    await this.coreSenderRPC.initiallyConnected.promise;

    this.initialized = true;
  };

  private generateDBQuery = <C extends DBFetchCall, R = ReturnType<DBQueries[C]>>(
    dbCall: C
  ): DBQueries[C] => {
    return (async (...params: Parameters<DBQueries[C]>): Promise<Awaited<R>> => {
      if (!this.initialized) {
        throw new Error('CoreClient not initialized');
      }
      return this.coreSenderRPC?.call('query', dbCall, params);
    }) as any; // TODO: Figure out how to type this properly
  };

  queries: DBQueries = {
    getDashboard: this.generateDBQuery('getDashboard'),
    getAllDashboards: this.generateDBQuery('getAllDashboards'),
    getDataSource: this.generateDBQuery('getDataSource'),
    getUserFile: this.generateDBQuery('getUserFile'),
    getAllDataSources: this.generateDBQuery('getAllDataSources'),
    getInitialSectionFields: this.generateDBQuery('getInitialSectionFields'),
    getDataSourceSection: this.generateDBQuery('getDataSourceSection'),
    getCategoryValues: this.generateDBQuery('getCategoryValues'),
    getDataSourceSectionsForDataSource: this.generateDBQuery('getDataSourceSectionsForDataSource'),
    queryDataPoints: this.generateDBQuery('queryDataPoints')
  };

  baseOptions = {
    retry: (failureCount: number, error: Error) => error instanceof RPCCancelError,
    networkMode: 'always' // So we always hit up workers, even when offline
  } as const;

  getQueryOptions = {
    getDashboard: (dashboardId: string | null) => {
      return queryOptions({
        ...this.baseOptions,
        queryKey: ['db', 'getDashboard', dashboardId],
        queryFn: () => {
          if (!dashboardId) throw new Error('Dashboard ID required');
          return this.queries['getDashboard'](dashboardId);
        },
        enabled: Boolean(dashboardId)
      });
    },
    getAllDashboards: () =>
      queryOptions({
        ...this.baseOptions,
        queryKey: ['db', 'getAllDashboards'],
        queryFn: () => this.queries['getAllDashboards']()
      }),
    getDataSource: (dataSourceId: string) => {
      return queryOptions({
        ...this.baseOptions,
        queryKey: ['db', 'getDataSource', dataSourceId],
        queryFn: () => {
          if (!dataSourceId) throw new Error('DataSource ID required');
          return this.queries['getDataSource'](dataSourceId);
        },
        enabled: Boolean(dataSourceId)
      });
    },
    getUserFile: (id: string) => {
      return queryOptions({
        ...this.baseOptions,
        queryKey: ['db', 'getUserFile', id],
        queryFn: () => {
          if (!id) throw new Error('UserFile ID required');
          return this.queries['getUserFile'](id);
        },
        enabled: Boolean(id)
      });
    },
    getAllDataSources: () =>
      queryOptions({
        ...this.baseOptions,
        queryKey: ['db', 'getAllDataSources'],
        queryFn: () => this.queries['getAllDataSources']()
      }),

    getCategoryValues: (sectionId: string | null, fieldName: string | null) => {
      return queryOptions({
        ...this.baseOptions,
        queryKey: ['db', 'getCategoryValues', sectionId, fieldName],
        queryFn: () => {
          if (!sectionId || !fieldName) throw new Error('Section ID and Field Name required');
          return this.queries['getCategoryValues'](sectionId, fieldName);
        },
        enabled: Boolean(sectionId && fieldName)
      });
    },
    getInitialSectionFields: (sectionId: string) => {
      return queryOptions({
        ...this.baseOptions,
        queryKey: ['db', 'getInitialSectionFields', sectionId],
        queryFn: () => {
          if (!sectionId) throw new Error('Section ID required');
          return this.queries['getInitialSectionFields'](sectionId);
        },
        enabled: Boolean(sectionId)
      });
    },
    getDataSourceSection: (dataSourceSectionId: string | null) => {
      return queryOptions({
        ...this.baseOptions,
        queryKey: ['db', 'getDataSourceSection', dataSourceSectionId],
        queryFn: () => {
          if (!dataSourceSectionId) throw new Error('DataSourceSection ID required');
          return this.queries['getDataSourceSection'](dataSourceSectionId);
        },
        enabled: Boolean(dataSourceSectionId)
      });
    },
    getDataSourceSectionsForDataSource: (dataSourceId: string | null) => {
      return queryOptions({
        ...this.baseOptions,
        queryKey: ['db', 'getDataSourceSectionsForDataSource', dataSourceId],
        queryFn: () => {
          if (!dataSourceId) throw new Error('DataSource ID required');
          return this.queries['getDataSourceSectionsForDataSource'](dataSourceId);
        },
        enabled: Boolean(dataSourceId)
      });
    },
    queryDataPoints: ({
      layerConfig,
      unitPreferences,
      pageIndex = 0,
      pageSize = 2500,
      defaultDir = 'DESC',
      selected,
      order
    }: {
      layerConfig: [BaseLayerConfig, ...LayerConfig[]] | null;
      unitPreferences: UnitPreferences;
      pageIndex?: number;
      pageSize?: number;
      defaultDir?: 'ASC' | 'DESC';
      selected: string[] | 'all';
      order: DataFetchOrder[] | null;
    }) => {
      return queryOptions({
        ...this.baseOptions,
        placeholderData(prevData, prevQuery) {
          /**
           * Keep previous data for queryDataPoints if the layer config is the same
           * but just pagination or sorting has changed. This helps with re-renders and weird flashing
           */
          const prevLayerConfig = (prevQuery?.queryKey[2] as any)?.layerConfig;
          // Custom equal check for layer configs that ignores fields that don't matter as much to the underlying data
          const isBasicallyEqual = isEqualWith(layerConfig, prevLayerConfig, (a, b, prop) => {
            if (prop === 'aggregateFields') return true; // Don't worry about aggregate fields changing
            return undefined;
          });
          return isBasicallyEqual ? keepPreviousData(prevData) : undefined;
        },
        enabled: Boolean(layerConfig),
        queryKey: [
          'db',
          'queryDataPoints',
          layerConfig,
          unitPreferences,
          pageIndex,
          pageSize,
          defaultDir,
          selected,
          order
        ],
        queryFn: () => {
          if (!layerConfig) throw new Error('LayerConfig required');
          return this.queries['queryDataPoints']({
            layerConfig,
            unitPreferences,
            pageIndex,
            pageSize,
            defaultDir,
            selected,
            order
          });
        }
      });
    }
  };

  /**
   * Calls needed to implement
   */
  getAuthToken = async () => {
    const session = this.store.get(authAtoms.session);
    return session?.getToken() ?? null;
  };

  initializeUserKeys = async () => {
    return this.coreSenderRPC?.call('initializeUserKeys');
  };

  startSync = async () => {
    return this.coreSenderRPC?.call('startSync');
  };

  /**
   * Device setup
   */
  isDeviceSetup = async () => {
    return this.coreSenderRPC?.call('isDeviceSetup');
  };

  recoverPrivateKey = async (recoveryCode: string, publicKey: string) => {
    return this.coreSenderRPC?.call('recoverPrivateKey', recoveryCode, publicKey);
  };

  getKeyPairForDeviceSetup = async () => {
    return this.coreSenderRPC?.call('getKeyPairForDeviceSetup');
  };

  getEncryptedPrivateKeyForDeviceSetup = async (devicePublicKey: string) => {
    return this.coreSenderRPC?.call('getEncryptedPrivateKeyForDeviceSetup', devicePublicKey);
  };

  setPrivateKeyFromDeviceSetup = async (
    encryptedPrivateKey: string,
    deviceKeyPair: { devicePublicKey: string; devicePrivateKey: string }
  ) => {
    return this.coreSenderRPC?.call(
      'setPrivateKeyFromDeviceSetup',
      encryptedPrivateKey,
      deviceKeyPair
    );
  };
}
