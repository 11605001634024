import { toast } from 'axil-web-ui';
import { atom, createStore } from 'jotai';
import { observe } from 'jotai-effect';
import CoreClient from 'src/core/core.client';
import { AdminAtoms } from './admin.atoms';
import { AuthAtoms } from './auth.atoms';
import { CommandsAtoms } from './commands.atoms';
import { DashboardAtoms } from './dashboards.atoms';
import { DataSourceFormAtoms } from './dataSourceForm.atoms';
import { DataSourceAtoms } from './dataSources.atoms';
import { HistoryAtoms } from './history.atoms';
import { OnboardingAtoms } from './onboarding.atoms';
import { SidebarAtoms } from './sidebar.atoms';
import { UIAtoms } from './ui.atoms';
import { atomWithUnwrap, Effect } from './utils';
import { WidgetFormAtoms } from './widgetForm.atoms';
import { WidgetAtoms } from './widgets.atoms';
import { SyncAtoms } from './sync.atoms';
import { UserAtoms } from './user.atoms';
import { queryClientAtom } from 'jotai-tanstack-query';
import { getQueryClient } from 'src/queryClient';
import { Register } from '@tanstack/react-router';
import { unwrap } from 'jotai/utils';
import { isTest } from 'src/utils';
import { Toast as NativeToast } from '@capacitor/toast';
import { Capacitor } from '@capacitor/core';
import { Dialog as NativeDialog } from '@capacitor/dialog';
import { DeviceSetupAtoms } from './device-setup.atoms';
import { isNotEmpty } from 'axil-utils';

export * from './dialogs.atoms';
export * from './sidebar.atoms';
export * from './status.atoms';

export const historyAtoms = new HistoryAtoms();
export const authAtoms = new AuthAtoms(historyAtoms);
export const userAtoms = new UserAtoms(authAtoms);
export const dashboardAtoms = new DashboardAtoms(historyAtoms, authAtoms);

export const dataSourceAtoms = new DataSourceAtoms(historyAtoms);
export const dataSourceFormAtoms = new DataSourceFormAtoms(historyAtoms);
export const adminAtoms = new AdminAtoms();
export const onboardingAtoms = new OnboardingAtoms(dataSourceAtoms, dashboardAtoms);
export const widgetAtoms = new WidgetAtoms(dashboardAtoms, historyAtoms);
export const widgetFormAtoms = new WidgetFormAtoms(dataSourceAtoms, userAtoms);
export const syncAtoms = new SyncAtoms();
export const uiAtoms = new UIAtoms(userAtoms);
export const sidebarAtoms = new SidebarAtoms(uiAtoms, historyAtoms);

export const deviceSetupAtoms = new DeviceSetupAtoms(authAtoms, userAtoms, historyAtoms);

export const commandsAtoms = new CommandsAtoms(
  dashboardAtoms,
  widgetAtoms,
  historyAtoms,
  sidebarAtoms
);

export const devToolsAtom = atom({
  jotai: false,
  query: false
});

export const setDevToolsAtom = atom(
  null,
  (_get, set, devTool: 'jotai' | 'query', show: boolean) => {
    set(devToolsAtom, current => ({
      ...current,
      [devTool]: show
    }));
  }
);

export const promptStateAtom = atom<
  | { prompting: false }
  | { prompting: true; text: string; description?: string; confirm: (confirmed: boolean) => void }
>({ prompting: false });

export const promptAtom = atom(null, async (_get, set, text: string, description?: string) => {
  if (Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('Dialog') && !description) {
    const { value: confirmed } = await NativeDialog.confirm({
      message: text,
      okButtonTitle: 'Yes',
      cancelButtonTitle: 'No'
    });
    return confirmed;
  }
  const confirmed = await new Promise<boolean>(resolve => {
    set(promptStateAtom, { prompting: true, text, description, confirm: resolve });
  });
  set(promptStateAtom, { prompting: false });
  return confirmed;
});

const coreClientInstanceAtom = atom<CoreClient | null>(null);

// Make sure its initialized before returning it
export const initialCoreClientAtom = atom(async get => {
  const core = get(coreClientInstanceAtom);
  if (!core) throw new Error('Core Client not set!');
  await get(authAtoms.initialized);
  if (!get(authAtoms.isLoggedIn)) return null;
  if (!core.initialized) await core.initialize();
  return core;
});

// A version that throws if not set so we don't null checks everywhere when logged in
export const coreClientAtom = atom(async get => {
  const core = await get(initialCoreClientAtom);
  if (!core) throw new Error('Core Client not set!');
  return core;
});

export const unwrappedCoreClientAtom = unwrap(initialCoreClientAtom);

export type StandalonePayload = {
  type: string;
  params: string[];
};

export const standaloneAtom = atom<StandalonePayload | null>(null);

export const isStandaloneAtom = atom(get => get(standaloneAtom) !== null);

export const pageTitleAtom = atom(get => {
  return (
    get(dashboardAtoms.currentDashboard)?.name ??
    get(dataSourceAtoms.currentDataSource)?.name ??
    'DayDash'
  );
});

const syncPageTitleAtom: Effect = (get, set) => {
  const pageTitle = get(pageTitleAtom);
  document.title = pageTitle;
};

// Should remain private. Its just to get a store reference for certain other atoms
export const _storeAtom = atom<ReturnType<typeof createStore> | null>(null);

// TODO: Try to handle all of this state in Jotai rather than in module memory in the use toast hook
export const toastAtom = atom(null, (_get, set, params: Parameters<typeof toast>[0] | string) => {
  if (typeof params === 'string') {
    params = { title: params };
  }
  if (
    Capacitor.isNativePlatform() &&
    Capacitor.isPluginAvailable('Toast') &&
    params.title &&
    !params.description
  ) {
    return NativeToast.show({
      text: params.title
    });
  }
  return toast(params);
});

export type Store = ReturnType<typeof createStore>;

const allObserversAtom = atom<ReturnType<typeof observe>[]>([]);
export const cleanupStoreAtom = atom(null, (get, set) => {
  const cleanups = get(allObserversAtom);
  cleanups.forEach(cleanup => cleanup());
  if (!isTest()) console.debug('Cleaned up all observers');
});
const createRootStore = (
  getRouter: (store: Store) => Register['router'],
  standalone?: StandalonePayload | null,
  // Extra param for testing and disabling certain effects
  enabledEffects:
    | 'all'
    | (
        | 'auth'
        | 'ui'
        | 'device-setup'
        | 'sync'
        | 'data-source-form'
        | 'dashboard'
        | 'widget-form'
        | 'page-title'
      )[] = 'all'
) => {
  const store = createStore();
  store.set(_storeAtom, store); // Only used by some atoms internally
  store.set(
    allObserversAtom,
    [
      // Add more as required
      enabledEffects === 'all' || enabledEffects.includes('auth') ? authAtoms.effects : null,
      enabledEffects === 'all' || enabledEffects.includes('data-source-form')
        ? dataSourceFormAtoms.effects
        : null,
      enabledEffects === 'all' || enabledEffects.includes('dashboard')
        ? dashboardAtoms.effects
        : null,
      historyAtoms.effects,
      sidebarAtoms.effects,
      enabledEffects === 'all' || enabledEffects.includes('sync') ? syncAtoms.effects : null,
      enabledEffects === 'all' || enabledEffects.includes('ui') ? uiAtoms.effects : null,
      enabledEffects === 'all' || enabledEffects.includes('widget-form')
        ? widgetFormAtoms.effects
        : null,
      enabledEffects === 'all' || enabledEffects.includes('device-setup')
        ? deviceSetupAtoms.effects
        : null,
      enabledEffects === 'all' || enabledEffects.includes('page-title') ? syncPageTitleAtom : null
    ]
      .flat()
      .filter(isNotEmpty)
      .map(effect => observe(effect, store))
  );
  store.set(coreClientInstanceAtom, new CoreClient(store));
  store.set(queryClientAtom, getQueryClient());
  store.set(standaloneAtom, standalone ?? null);
  store.set(historyAtoms.router, getRouter(store));
  return store;
};

export default createRootStore;
