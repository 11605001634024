import { getServiceURL } from 'src/environment';
import { SplashScreen } from '@capacitor/splash-screen';
import { Capacitor } from '@capacitor/core';

export const isTest = () => {
  return Boolean(process.env.NODE_ENV === 'test');
};

export const showPageLoader = async () => {
  document.getElementById('page-loader')?.style.removeProperty('display');
  if (Capacitor.isNativePlatform()) {
    await SplashScreen.show();
  }
};

export const hidePageLoader = async () => {
  document.getElementById('page-loader')?.style.setProperty('display', 'none');
  if (Capacitor.isNativePlatform()) {
    await SplashScreen.hide();
  }
};

export const isWebworker = () =>
  typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope;

export const getLogoSrc = (logo: string) => {
  return `${getServiceURL()}/data-source/logos/${logo}`;
};

export const moveItem = <T>(arr: Array<T>, from: number, to: number) => {
  const cloned = [...arr];
  cloned.splice(to, 0, cloned.splice(from, 1)[0]);
  return cloned;
};

export const isInViewport = (elem: Element) => {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

// Use negative numbers for scaling down, positive for scaling
export const scaleBoundingBox = (initial: DOMRect, pixels: number): DOMRect => {
  const updated = {
    height: initial.height + pixels * 2,
    width: initial.width + pixels * 2,
    x: initial.x - pixels,
    y: initial.y - pixels,
    left: initial.left - pixels,
    right: initial.right + pixels,
    top: initial.top - pixels,
    bottom: initial.bottom + pixels,
    toJSON: () => updated
  };
  return updated;
};

export const isInBoundingBox = (
  rect: DOMRect,
  { clientX, clientY }: { clientX: number; clientY: number }
) => {
  return (
    clientX >= rect.left && clientX <= rect.right && clientY >= rect.top && clientY <= rect.bottom
  );
};

export const clearIndexedDB = async (databaseName: string) => {
  const request = indexedDB.deleteDatabase(databaseName);
  return new Promise<void>((resolve, reject) => {
    request.onsuccess = () => {
      resolve();
    };
    request.onerror = () => {
      reject(request.error);
    };
    request.onblocked = () => {
      reject(new Error('Clearing the database is blocked by other open connections.'));
    };
  });
};

// Convert oklch color to RGBA string. Some libraries don't support oklch directly.
export const oklchToRgb = (val: string) => {
  // Attempt to parse "oklch(L C H[/alpha])"
  const match = val.trim().match(/oklch\(\s*([\d.]+)\s+([\d.]+)\s+([\d.]+)(?:\s*\/\s*([\d.]+))?\)/);
  if (!match) {
    throw new Error(`Invalid oklch color: ${val}`);
  }
  const [_, l, c, h, alpha] = match;
  const L = parseFloat(l);
  const C = parseFloat(c);
  const H = parseFloat(h);
  const A = alpha ? parseFloat(alpha) : 1;

  // Convert oklch -> oklab
  const a_ = C * Math.cos((H * Math.PI) / 180);
  const b_ = C * Math.sin((H * Math.PI) / 180);

  // Convert oklab -> linear sRGB using standard matrix transforms
  // (reference: https://bottosson.github.io/posts/oklab/)
  const l_ = (L + 0.3963377774 * a_ + 0.2158037573 * b_) ** 3;
  const m_ = (L - 0.1055613458 * a_ - 0.0638541728 * b_) ** 3;
  const s_ = (L - 0.0894841775 * a_ - 1.291485548 * b_) ** 3;

  let r = +(4.0767416621 * l_ - 3.3077115913 * m_ + 0.2309699292 * s_).toFixed(0);
  let g = +(-1.2684380046 * l_ + 2.6097574011 * m_ - 0.3413193965 * s_).toFixed(0);
  let b = +(-0.0045164051 * l_ - 0.0053981164 * m_ + 1.0099999999 * s_).toFixed(0);

  // Clamp and return as RGBA string
  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));
  return `rgba(${r}, ${g}, ${b}, ${A})`;
};
