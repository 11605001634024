import { QueryClient, QueryFunction } from '@tanstack/react-query';
import { isAxiosError } from 'axil-utils';
import axios from 'axios';
import isString from 'lodash/isString';

const defaultQueryFunction: QueryFunction = async ({ queryKey, signal }) => {
  const path = queryKey[0];
  if (!path || !isString(path)) return null;
  const { data } = await axios.get(path, { signal });
  return data;
};

export const getQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        queryFn: defaultQueryFunction,
        retry: (failureCount, error) => {
          if (isAxiosError(error) && error.response) {
            const { status } = error.response;
            if (400 <= status && status < 500) return false;
            if (500 <= status) return failureCount < 1;
          }
          return failureCount < 2;
        },
        // We want a higher time than the default here because we persist query values in the cache
        gcTime: 1000 * 60 * 60 * 24 // 24 hours
      },
      mutations: {}
    }
  });
};
