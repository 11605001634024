import { atom } from 'jotai';
import { queryClientAtom } from 'jotai-tanstack-query';
import isEqual from 'lodash/isEqual';
import { SYNC_STATE_BROADCAST_CHANNEL, SYNC_STATE_REQUEST, SyncState } from 'src/core/types';
import { authAtoms, unwrappedCoreClientAtom } from '.';
import atomWithDebounce, { Effect, labelAtoms } from './utils';

@labelAtoms
export class SyncAtoms {
  /**
   * This takes broadcast messages from a sync worker and adds it in state.
   *
   * The broadcast channel helps to subscribe to changes all the way from the sync worker
   * without adding a bunch of back and forth messages through the DB client
   */
  syncState = atomWithDebounce<SyncState>({
    connected: false,
    dataSourceSectionShapesInitialized: false,
    dataPointShapesInitialized: false,
    shapes: []
  });

  // Intermediate atoms need to be mounted apparently
  currentUpToDateShapeHandles = atom(get => {
    const syncState = get(this.syncState.debouncedValueAtom);
    return new Map(
      syncState.shapes
        .filter(shape => shape.isUpToDate)
        .map(shape => [
          shape.name,
          { shapeHandle: shape.shapeHandle, lastOffset: shape.lastOffset }
        ])
    );
  });

  initialized = atom(false);

  syncStateBroadcastChannel: Effect = (get, set) => {
    if (!get(authAtoms.isLoggedIn)) return;
    const broadcastChannel = new BroadcastChannel(SYNC_STATE_BROADCAST_CHANNEL);
    broadcastChannel.onmessage = e => {
      if (e.data === SYNC_STATE_REQUEST) return;
      const newState = e.data as SyncState;
      set(this.syncState.debouncedValueAtom, current => {
        if (isEqual(current, newState)) return current;
        return newState;
      });
    };
    broadcastChannel.postMessage(SYNC_STATE_REQUEST); // Get the initial state on load
    return () => {
      broadcastChannel.close();
    };
  };

  syncInitializedCheck: Effect = (get, set) => {
    if (get.peek(this.initialized)) return; // You can stop once its initialized
    const syncState = get(this.syncState.debouncedValueAtom);
    const fullySynced =
      syncState.connected &&
      // Only worry about the data source section stuff here in case there are no sections or anything
      syncState.dataSourceSectionShapesInitialized &&
      syncState.dataPointShapesInitialized;
    // syncState.shapes.every(shape => shape.initialized);
    if (fullySynced) set(this.initialized, true);
  };

  private previousUpToDateShapeHandles = atom<
    Map<string, { shapeHandle: string | null; lastOffset: string | null }>
  >(new Map());

  /**
   * The goal here is to find when changes are made after syncing and then invalidate all of the appropriate
   * queries. We can do that by identify which shapes are up to date and when there shapeHandle actually changes
   */
  autoRefetchDBQueries: Effect = (get, set) => {
    const currentUpToDateShapeHandles = get(this.currentUpToDateShapeHandles);
    const coreClient = get(unwrappedCoreClientAtom);
    if (!coreClient?.initialized) return;
    // Important to use get.peek here or otherwise downstream updates are blocked because Jotai prevents infinite loops
    const previousUpToDateShapeHandles = get.peek(this.previousUpToDateShapeHandles);
    if (isEqual(currentUpToDateShapeHandles, previousUpToDateShapeHandles)) return;
    set(this.previousUpToDateShapeHandles, currentUpToDateShapeHandles);
    const queryClient = get(queryClientAtom);
    // For anything that wasn't up to date but is now up to date, invalidate queries so they refetch
    for (const [shapeName, { shapeHandle, lastOffset }] of currentUpToDateShapeHandles) {
      // No Change, carry on
      const prev = previousUpToDateShapeHandles.get(shapeName);
      if (prev && prev.shapeHandle === shapeHandle && prev.lastOffset === lastOffset) continue;
      if (shapeName === 'dashboards') {
        queryClient.invalidateQueries({ queryKey: ['db', 'getDashboard'] });
        queryClient.invalidateQueries({ queryKey: ['db', 'getAllDashboards'] });
      } else if (shapeName === 'dataSources') {
        queryClient.invalidateQueries({ queryKey: ['db', 'getDataSource'] });
        queryClient.invalidateQueries({ queryKey: ['db', 'getAllDataSources'] });
      } else if (shapeName.startsWith('dataSourceSections')) {
        queryClient.invalidateQueries({ queryKey: ['db', 'getDataSourceSection'] });
        queryClient.invalidateQueries({ queryKey: ['db', 'getDataSourceSectionsForDataSource'] });
        queryClient.invalidateQueries({ queryKey: ['db', 'getInitialSectionFields'] });
      } else if (shapeName.startsWith('dataPoints')) {
        const invalidateDataPointQueries = async () => {
          const dsId = shapeName.split(':')[1];
          const sections = await coreClient?.queries.getDataSourceSectionsForDataSource(dsId);
          const sectionIds = new Set(sections?.map(s => s.id) ?? []);
          queryClient.invalidateQueries({
            queryKey: ['db', 'queryDataPoints'],
            type: 'all',
            predicate(query) {
              const key = query.queryKey;
              const param = key[2] as any;
              if (param && 'layerConfig' in param) {
                return sectionIds.has(param.layerConfig[0].sectionId);
              }
              return false;
            }
          });
          queryClient.invalidateQueries({
            queryKey: ['db', 'getInitialSectionFields'],
            type: 'all',
            predicate(query) {
              const key = query.queryKey;
              return sectionIds.has(key[2] as any);
            }
          });
          queryClient.invalidateQueries({
            queryKey: ['db', 'getCategoryValues'],
            type: 'all',
            predicate(query) {
              const key = query.queryKey;
              return sectionIds.has(key[2] as any);
            }
          });
        };
        invalidateDataPointQueries();
      } else if (shapeName.startsWith('userFile')) {
        queryClient.invalidateQueries({ queryKey: ['db', 'getUserFile'] });
      }
    }
  };

  effects: Effect[] = [
    this.syncStateBroadcastChannel,
    this.syncInitializedCheck,
    this.autoRefetchDBQueries
  ];
}
