import { ParsedLocation } from '@tanstack/react-router';
import { getMainRouter } from '.';
import { authAtoms, Store, userAtoms } from 'src/state';

// Global functionality
declare module '@tanstack/react-router' {
  interface HistoryState {
    redirect?: ParsedLocation;
  }

  interface Register {
    /**
     * NOTE: This type is kind of a lie, but a useful lie.
     *
     * Basically, for standalone experiences, we will only have a subset/slice of the overall route tree.
     * Therefore, in those contexts, navigating to a route that is not in the current tree will throw an error.
     *
     * This is expected and is a bug we have to address, but Typescript will not help us here. The best
     * we could do is cast the routing type later but that will greatly hinder the dev experience.
     */
    router: ReturnType<typeof getMainRouter>;
  }
}

export type RouterId = keyof ReturnType<typeof getMainRouter>['routesByPath'];

export type RouterContext = {
  store: Store;
};

export const getCurrentUserFromRouteContext = async (context: RouterContext) => {
  const { store } = context;
  await store.get(authAtoms.initialized);
  return store.get(userAtoms.current);
};
