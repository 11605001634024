import { createFormHook, createFormHookContexts } from '@tanstack/react-form';
import { DeepPartial } from 'axil-utils';
import { type Widget, getLatestWidgetOfType, widgetSchema } from 'daydash-data-structures';
import { useAtomValue, useSetAtom } from 'jotai';
import { useLayoutEffect, useMemo } from 'react';
import { useAppForm } from 'src/components/common/forms';
import { widgetFormAtoms } from 'src/state';
import { v4 as uuid } from 'uuid';

export type WidgetFormPath = Parameters<WidgetFormApi['getFieldValue']>[0];

export type WidgetFormDefaultValue = DeepPartial<Widget> & { id: string };

export function useAppWidgetForm(
  initial: Widget | null,
  onSubmit: (newWidget: Widget) => Promise<void>
) {
  const initialValues = useMemo((): WidgetFormDefaultValue => {
    // v will populated on submission depending on the type
    let parsed: Widget | null = null;
    // Just make sure we can parse everything out if we can. This will also help apply transforms
    if (initial) {
      const result = widgetSchema.safeParse(getLatestWidgetOfType(initial));
      if (result.success) parsed = result.data;
    }
    return initial ? { ...(parsed || initial) } : { id: uuid() };
  }, [initial, widgetSchema]);
  const form = useAppForm({
    defaultValues: initialValues,
    validators: { onChange: widgetSchema },
    onSubmit: ({ value }) => onSubmit(widgetSchema.parse(value))
  });
  const initializeWidgetForm = useSetAtom(widgetFormAtoms.initializeWidgetForm);
  const cleanupWidgetForm = useSetAtom(widgetFormAtoms.cleanupWidgetForm);
  useLayoutEffect(() => {
    initializeWidgetForm(form);
    return () => cleanupWidgetForm();
  }, [form]);
  return form;
}

export function useCurrentWidgetForm() {
  const form = useAtomValue(widgetFormAtoms.widgetForm);
  if (!form) throw new Error('Form not found');
  return form;
}

export type WidgetFormApi = ReturnType<typeof useAppWidgetForm>;
