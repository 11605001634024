import { isNotEmpty } from 'axil-utils';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { atom } from 'jotai';
import { atomWithMutation, atomWithQuery, queryClientAtom } from 'jotai-tanstack-query';
import { _storeAtom, promptAtom, toastAtom, unwrappedCoreClientAtom } from '.';
import type { Command, CommandGroup } from './commands.atoms';
import { DataSource } from 'src/types/entities';
import CoreClient from 'src/core/core.client';
import { QueryClient } from '@tanstack/react-query';
import { SortWeights } from './common';
import { atomFamily } from 'jotai/utils';
import { HistoryAtoms } from './history.atoms';
import { labelAtoms } from './utils';

type MutationContext = {
  previousDataSource: DataSource | undefined;
  previousDataSources: DataSource[] | undefined;
};

type PatchPayload = {
  id: string;
  name?: string;
  sortWeight?: string;
};

@labelAtoms
export class DataSourceAtoms {
  constructor(private historyAtoms: HistoryAtoms) {}

  private optimisticallyUpdateDataSource = async (
    updatedDataSource: Partial<DataSource> & { id: string },
    coreClient: CoreClient,
    queryClient: QueryClient
  ) => {
    const queryKey = coreClient.getQueryOptions.getDataSource(updatedDataSource.id).queryKey;
    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries({ queryKey });
    // Snapshot the previous values
    const previousDataSource = queryClient.getQueryData<DataSource | undefined>(queryKey);
    // Optimistically update to the new value
    queryClient.setQueryData<DataSource | null>(queryKey, old => {
      if (!old) return null; // Should really never happen
      return { ...old, ...updatedDataSource };
    });
    return previousDataSource;
  };

  private optimisticallyUpdateAllDataSources = async (
    updatedDataSources: (Partial<DataSource> & { id: string })[],
    coreClient: CoreClient,
    queryClient: QueryClient
  ) => {
    const queryKey = coreClient.getQueryOptions.getAllDataSources().queryKey;
    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries({ queryKey });
    // Snapshot the previous values
    const previousDataSources = queryClient.getQueryData<DataSource[] | undefined>(queryKey);
    // Optimistically update to the new value
    const byId = new Map(updatedDataSources?.map(d => [d.id, d]) ?? []);
    queryClient.setQueryData<DataSource[] | undefined>(queryKey, old => {
      if (!old) return;
      return old.map(d => {
        const updated = byId.get(d.id);
        if (updated) return { ...d, ...updated };
        return d;
      });
    });
    return previousDataSources;
  };

  /**
   * Patches
   */

  private patchDataSourceMutation = atomWithMutation<
    AxiosResponse<DataSource>,
    PatchPayload,
    AxiosError,
    MutationContext
  >(get => {
    const coreClient = get(unwrappedCoreClientAtom);
    const queryClient = get(queryClientAtom);
    return {
      mutationFn: payload => axios.patch(`/data-source/${payload.id}`, payload),
      onMutate: async newDataSource => {
        if (!coreClient) {
          throw new Error('Core client not initialized');
        }
        return {
          previousDataSource: await this.optimisticallyUpdateDataSource(
            newDataSource,
            coreClient,
            queryClient
          ),
          previousDataSources: await this.optimisticallyUpdateAllDataSources(
            [newDataSource],
            coreClient,
            queryClient
          )
        };
      },
      onError: (error, variables, context) => {
        if (!context || !coreClient) return;
        queryClient.setQueryData(
          coreClient.getQueryOptions.getAllDataSources().queryKey,
          context.previousDataSources
        );
        queryClient.setQueryData(
          coreClient.getQueryOptions.getDataSource(variables.id).queryKey,
          context.previousDataSource
        );
      }
    };
  });

  patchDataSource = atom(null, async (get, set, payload: PatchPayload) => {
    await get(this.patchDataSourceMutation).mutateAsync(payload);
    set(toastAtom, { title: 'Data source updated' });
  });

  /**
   * Sort weights
   */
  private updateSortWeightsMutation = atomWithMutation<
    AxiosResponse<DataSource>[],
    SortWeights,
    SortWeights,
    DataSource[]
  >(get => {
    const isNewWeight = (updated: [string, string | null]): updated is [string, string] => {
      return Boolean(updated[1]);
    };
    const coreClient = get(unwrappedCoreClientAtom);
    const queryClient = get(queryClientAtom);
    return {
      mutationFn: weights => {
        return Promise.all(
          Object.entries(weights)
            .filter(isNewWeight)
            .map(async ([id, newWeight]) =>
              axios.patch(`/data-source/${id}`, { sortWeight: newWeight })
            )
        );
      },
      onMutate: async newWeights => {
        if (!coreClient) {
          throw new Error('Core client not initialized');
        }
        return this.optimisticallyUpdateAllDataSources(
          Object.entries(newWeights).map(([id, sortWeight]) => ({ id, sortWeight })),
          coreClient,
          queryClient
        );
      },
      onError: (error, newWeights, previousDataSources) => {
        if (!previousDataSources || !coreClient) return;
        queryClient.setQueryData(
          coreClient.getQueryOptions.getAllDataSources().queryKey,
          previousDataSources
        );
      }
    };
  });

  updateSortWeights = atom(null, async (get, set, newWeights: SortWeights) => {
    await get(this.updateSortWeightsMutation).mutateAsync(newWeights);
  });

  /**
   * Deletes
   */

  private deleteDataSourceMutation = atomWithMutation<AxiosResponse, string, AxiosError>(get => {
    const coreClient = get(unwrappedCoreClientAtom);
    if (!coreClient) throw new Error('Core client not found');
    const queryClient = get(queryClientAtom);
    return {
      mutationFn: id => axios.delete('/data-source/' + id),
      async onSuccess(_, id) {
        const dataSourcesQueryKey = coreClient.getQueryOptions.getAllDataSources().queryKey;
        // Optimistically update to the new value while we wait for the sync
        queryClient.setQueryData<DataSource[] | undefined>(dataSourcesQueryKey, old => {
          if (!old) return;
          return old.filter(d => d.id !== id);
        });
        const dataSourceQueryLey = coreClient.getQueryOptions.getDataSource(id!).queryKey;
        queryClient.setQueryData<DataSource | null>(dataSourceQueryLey, null);
      }
    };
  });

  deleteDataSource = atom(null, async (get, set, id: string) => {
    const dataSource = get(this.list)?.data?.find(d => d.id === id);
    if (!dataSource) throw new Error('DataSource not found');
    const confirmed = await set(promptAtom, `Are you sure you want to delete ${dataSource.name}?`);
    if (!confirmed) return;
    await get(this.deleteDataSourceMutation).mutateAsync(id);
    // Navigate away if on the dataSource page
    if (get(this.dataSourcePageRoute)?.params.id === id) {
      const router = get(this.historyAtoms.router);
      router?.navigate({ to: '/' });
    }
    set(toastAtom, { title: 'Data Source Deleted', description: dataSource.name });
  });

  list = atomWithQuery(get => {
    const coreClient = get(unwrappedCoreClientAtom);
    if (!coreClient) {
      return { enabled: false, queryKey: [] };
    }
    return coreClient.getQueryOptions.getAllDataSources();
  });

  sectionsForDataSource = atomFamily((dataSourceId: string) => {
    return atomWithQuery(get => {
      const coreClient = get(unwrappedCoreClientAtom);
      return coreClient!.getQueryOptions.getDataSourceSectionsForDataSource(dataSourceId);
    });
  });

  private dataSourcePageRoute = atom(get => {
    const routeMatches = get(this.historyAtoms.routeMatches);
    return routeMatches?.find(match => match.routeId === '/authed/main/data-source/$id');
  });

  currentDataSource = atom(get => {
    const dataSourceList = get(this.list);
    if (!dataSourceList) return null;
    const pageRoute = get(this.dataSourcePageRoute);
    const currentDataSourceId: string | null = pageRoute?.params.id;
    const currentDataSource = currentDataSourceId
      ? dataSourceList?.data?.find(d => d.id === currentDataSourceId)
      : null;
    return currentDataSource;
  });

  private syncDataSourceMutation = atomWithMutation<AxiosResponse, string, AxiosError>(get => {
    return {
      mutationFn: id => axios.post(`/data-source/sync/${id}`)
    };
  });

  syncDataSource = atom(null, async (get, set, id: string) => {
    set(toastAtom, { title: 'Data source sync started' });
    await get(this.syncDataSourceMutation).mutateAsync(id);
    set(toastAtom, { title: 'Data source synchronized!' });
  });

  syncingDataSource = atom(get => {
    return get(this.syncDataSourceMutation).isPending;
  });

  testConnectionMutation = atomWithMutation<AxiosResponse, string, AxiosError>(get => {
    return {
      mutationFn: id => axios.post(`/data-source/test-connection/${id}`)
    };
  });

  commands = atom<CommandGroup | null>(get => {
    const store = get(_storeAtom);
    if (!store) return null;
    const router = get(this.historyAtoms.router);
    const currentDataSource = get(this.currentDataSource);
    const dataSourceList = get(this.list);
    if (!dataSourceList) return null;
    const currentDataSourceCommands: Command[] = [];
    if (currentDataSource) {
      // TODO: Add delete data source
    }
    return {
      label: 'Data Source',
      id: 'dataSource',
      commands: [
        ...currentDataSourceCommands,
        ...(dataSourceList?.data?.map(dataSource => [
          {
            label: `Go to ${dataSource.name}`,
            id: `go-to-data-source-${dataSource.id}`,
            handler: () =>
              router?.navigate({ to: '/data-source/$id', params: { id: dataSource.id } })
          },
          {
            label: `Delete ${dataSource.name}`,
            id: `delete-data-source-${dataSource.id}`,
            handler: () => store.set(this.deleteDataSource, dataSource.id)
          }
        ]) ?? [])
      ]
        .flat()
        .filter(isNotEmpty)
    };
  });
}
