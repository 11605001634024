import { Clerk } from '@clerk/clerk-js';
import type { InternalAxiosRequestConfig } from 'axios';
import axios from 'axios';
import { atom } from 'jotai';
import { atomWithQuery, queryClientAtom } from 'jotai-tanstack-query';
import { unwrap } from 'jotai/utils';
import { getLocalDBName } from 'src/core/types';
import { getServiceURL } from 'src/environment';
import persister from 'src/queryPersister';
import { clearIndexedDB } from 'src/utils';
import { HistoryAtoms } from './history.atoms';
import { atomWithUnwrap, Effect } from './utils';

const clerkPubKey = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

type AuthUser = NonNullable<Clerk['user']>;

type Session = NonNullable<Clerk['session']>;

type AuthClient = NonNullable<Clerk['client']>;

export class AuthAtoms {
  constructor(private historyAtoms: HistoryAtoms) {}

  clerk = atom(async () => {
    const clerk = new Clerk(clerkPubKey);
    await clerk.load({
      supportEmail: 'support@axil.llc',
      signInUrl: '/login'
      // signUpUrl: '/signup'
    });
    return clerk;
  });

  initialized = atom(async get => {
    const clerk = await get(this.clerk);
    return clerk.loaded;
  });

  private unwrappedClerk = unwrap(this.clerk);

  user = atom<AuthUser | null>(null);

  session = atom<Session | null>(null);

  client = atom<AuthClient | null>(null);

  /**
   * Figure out initial clerk loading
   */
  private activeUserSync: Effect = (get, set) => {
    const clerk = get(this.unwrappedClerk);
    if (!clerk) return;
    set(this.user, clerk.user ?? null);
    set(this.session, clerk.session ?? null);
    set(this.client, clerk.client ?? null);
    return clerk.addListener(emission => {
      set(this.user, emission.user ?? null);
      set(this.session, emission.session ?? null);
      set(this.client, emission.client ?? null);
    });
  };

  private setAxiosAuthInterceptor: Effect = get => {
    const session = get(this.session);
    const id = axios.interceptors.request.use(async config => {
      const headers = { ...config.headers };
      const accessToken = (await session?.getToken()) ?? null;
      if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`;
      return {
        ...config,
        headers,
        withCredentials: true,
        baseURL: config.baseURL ?? getServiceURL()
      } as InternalAxiosRequestConfig<any>;
    });
    return () => {
      // Reset when the session changes
      axios.interceptors.request.eject(id);
    };
  };

  userId = atomWithUnwrap(get => {
    const user = get(this.user);
    return user?.id ?? null;
  });

  isLoggedIn = atom(get => {
    return Boolean(get(this.session));
  });

  userSessionsQuery = atomWithQuery(get => {
    const user = get(this.user);
    return {
      queryFn: () => user?.getSessions(),
      queryKey: ['userSessions'],
      enabled: Boolean(user)
    };
  });

  userSessions = atom(get => {
    return get(this.userSessionsQuery).data ?? [];
  });

  /**
   * Login atoms
   */
  loginError = atom<string | null>(null);
  loginWithPassword = atom(null, async (get, set, email: string, password: string) => {
    try {
      set(this.loginError, null);
      const clerk = await get(this.clerk);
      const signInAttempt = await clerk.client?.signIn.create({
        strategy: 'password',
        identifier: email,
        password
      });
      // If the sign-in is complete, set the user as active
      if (signInAttempt?.status === 'complete') {
        await clerk.setActive({ session: signInAttempt.createdSessionId });
        const router = get(this.historyAtoms.router);
        await router?.invalidate();
      } else {
        console.error(signInAttempt);
      }
    } catch (err) {
      if (err instanceof Error) {
        set(this.loginError, err.message);
      } else {
        throw err;
      }
    }
  });

  // TODO: Add login with email code, Google, and Apple

  /**
   * Logout atoms
   */

  loggingOut = atom(false);

  private cleanupLocalStorage = atom(null, async (get, set) => {
    const queryClient = get(queryClientAtom);
    const userId = get(this.userId);
    if (!userId) throw new Error('No user ID found');
    queryClient.getQueryCache().clear();
    await persister.removeClient();
    try {
      await clearIndexedDB(getLocalDBName(userId));
    } catch (err) {
      console.error(err);
    }
  });

  logout = atom(null, async (get, set) => {
    set(this.loggingOut, true);
    await set(this.cleanupLocalStorage);
    await (await get(this.clerk)).signOut();
    // Reload the app when you log out
    window.location.replace('/');
  });

  // Keep local storage and what not around when you reauthenticate
  reauthenticate = atom(null, async (get, set) => {
    await (await get(this.clerk)).signOut();
    const router = get(this.historyAtoms.router);
    return router?.navigate({ to: '/login' });
  });

  effects: Effect[] = [this.activeUserSync, this.setAxiosAuthInterceptor];
}
