import { isNotEmpty } from 'axil-utils';
import { atom } from 'jotai';
import { _storeAtom, dataSourceAtoms, devToolsAtom, setDevToolsAtom, SidebarAtoms } from '.';
import { updateDialogAtom } from './dialogs.atoms';
import { WidgetAtoms } from './widgets.atoms';
import { DashboardAtoms } from './dashboards.atoms';
import { HistoryAtoms } from './history.atoms';
import { labelAtoms } from './utils';

export type Command = {
  label: string;
  icon?: React.ReactNode;
  keywords?: string[];
  id: string;
  handler: () => void;
};

export type CommandGroup = {
  label: string;
  id: string;
  commands: Command[];
};

@labelAtoms
export class CommandsAtoms {
  constructor(
    private dashboardAtoms: DashboardAtoms,
    private widgetAtoms: WidgetAtoms,
    private historyAtoms: HistoryAtoms,
    private sidebarAtoms: SidebarAtoms
  ) {}

  private sidebarCommands = atom<Command[]>(get => {
    const store = get(_storeAtom);
    const commands: Command[] = [];
    if (!store) return [];
    const sidebarState = get(this.sidebarAtoms.sidebar);
    const canExpandSidebar = get(this.sidebarAtoms.canExpand);
    if (canExpandSidebar && !sidebarState.expanded)
      commands.push({
        label: 'Expand Sidebar',
        id: 'expand-sidebar',
        handler: () => store.set(this.sidebarAtoms.expand)
      });
    if (canExpandSidebar && sidebarState.expanded)
      commands.push({
        label: 'Collapse Sidebar',
        id: 'collapse-sidebar',
        handler: () => store.set(this.sidebarAtoms.collapse)
      });
    if (!sidebarState.expanded && !sidebarState.open)
      commands.push({
        label: 'Open Sidebar',
        id: 'open-sidebar',
        handler: () => store.set(this.sidebarAtoms.open)
      });
    if (!sidebarState.expanded && sidebarState.open)
      commands.push({
        label: 'Close Sidebar',
        id: 'close-sidebar',
        handler: () => store.set(this.sidebarAtoms.close)
      });
    return commands;
  });

  private devToolsCommands = atom<CommandGroup | null>(get => {
    if (!import.meta.env.DEV) return null;
    const store = get(_storeAtom);
    if (!store) return null;
    const router = get(this.historyAtoms.router);
    const devToolsState = get(devToolsAtom);
    return {
      label: 'Dev Tools',
      id: 'dev-tools',
      commands: [
        {
          label: !devToolsState.jotai ? 'Show Jotai Dev Tools' : 'Hide Jotai Dev Tools',
          id: 'toggle-jotai',
          handler: () => store.set(setDevToolsAtom, 'jotai', !devToolsState.jotai)
        },
        {
          label: !devToolsState.query ? 'Show Query Dev Tools' : 'Hide Query Dev Tools',
          id: 'toggle-query',
          handler: () => store.set(setDevToolsAtom, 'query', !devToolsState.query)
        },
        {
          label: 'Go to Admin Console',
          id: 'go-to-admin',
          handler: () => router?.navigate({ to: '/admin' })
        }
      ]
    };
  });

  commands = atom<CommandGroup[]>(get => {
    const store = get(_storeAtom);
    if (!store) return [];
    const router = get(this.historyAtoms.router);
    const commandGroups: CommandGroup[] = [];
    // General
    commandGroups.push({
      label: 'General',
      id: 'general',
      commands: [
        {
          label: 'Create Dashboard',
          id: 'create-dashboard',
          handler: () => store.set(updateDialogAtom, { type: 'CreateDashboard', payload: null })
        },
        {
          label: 'Create Data Source',
          id: 'create-dataSource',
          handler: () => store.set(updateDialogAtom, { type: 'CreateDataSource', payload: null })
        },
        {
          label: 'Go back',
          id: 'go-back',
          handler: () => router?.history.back()
        },
        {
          label: 'Go forward',
          id: 'go-forward',
          handler: () => router?.history.forward()
        },
        ...get(this.sidebarCommands)
      ].filter(isNotEmpty)
    });

    const dashboardCommandGroup = get(this.dashboardAtoms.commands);
    if (dashboardCommandGroup) commandGroups.push(dashboardCommandGroup);
    // Widget
    const widgetCommandGroup = get(this.widgetAtoms.commands);
    if (widgetCommandGroup) commandGroups.push(widgetCommandGroup);
    // Datasource
    const dataSourceCommandGroup = get(dataSourceAtoms.commands);
    if (dataSourceCommandGroup) commandGroups.push(dataSourceCommandGroup);
    // Account
    commandGroups.push({
      label: 'Account',
      id: 'account',
      commands: [
        {
          label: 'Settings',
          id: 'settings',
          handler: () => store.set(updateDialogAtom, { type: 'AccountPreferences', payload: null })
        }
      ]
    });
    // Dev Tools
    const devToolsCommandGroup = get(this.devToolsCommands);
    if (devToolsCommandGroup) commandGroups.push(devToolsCommandGroup);

    return commandGroups;
  });
}
