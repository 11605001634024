export type Cleanup = () => void;

export type RPCResult<R = any, E = any> =
  | {
      id: string;
      success: true;
      result: R;
    }
  | {
      id: string;
      success: false;
      error: E;
    };

export type RPCCall<P = any[]> = {
  id: string;
  method: string;
  params: P;
};

export type CommonRPCMessage = RPCResult | RPCCall | 'heartbeat' | 'disconnect';

export const isRPCResult = (message: CommonRPCMessage): message is RPCResult => {
  return typeof message !== 'string' && 'success' in message;
};

export const isRPCCall = (message: CommonRPCMessage): message is RPCCall => {
  return typeof message !== 'string' && 'method' in message;
};

export interface MessageTarget {
  postMessage(message: CommonRPCMessage): void;
  addMessageListener: (listener: (message: CommonRPCMessage) => void) => Cleanup;
}

export type Cfg = {
  [method: string]: (...params: any[]) => any;
};

export class RPCCancelError extends Error {
  constructor() {
    super('RPC call was canceled');
  }
}
