import { createRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';
import UnauthenticatedPageLayout from '../components/layouts/UnauthenticatedPageLayout';
import LoginPage from '../components/pages/LoginPage';
import SignupPage from '../components/pages/Signup';
import { getCurrentUserFromRouteContext } from './common';
import { rootRoute } from './root';

const unauthedRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: 'unauthed',
  component: UnauthenticatedPageLayout,
  validateSearch: (search: Record<string, unknown>) =>
    z.object({ redirect: z.string().optional() }).parse(search),
  beforeLoad: async ({ search, context }) => {
    // If they are logged in, redirect them
    if (await getCurrentUserFromRouteContext(context)) {
      throw redirect({ to: search.redirect || '/' });
    }
  }
});

const loginRoute = createRoute({
  getParentRoute: () => unauthedRoute,
  path: '/login',
  component: LoginPage
});

const signupRoute = createRoute({
  getParentRoute: () => unauthedRoute,
  path: '/signup',
  component: SignupPage
});

export const getUnauthedRouteTree = () => {
  return unauthedRoute.addChildren([loginRoute, signupRoute]);
};
