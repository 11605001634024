import { dark as clerkDarkBaseTheme } from '@clerk/themes';
import { Theme as ClerkTheme } from '@clerk/types';
import { buildChartTheme as buildVisxChartTheme } from '@visx/xychart';
import { oklchToRgb } from './utils';

export type ThemeMode = 'dark' | 'light';

export type BreakPoint = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export const vizColorKeys = [
  'red',
  'orange',
  'amber',
  'yellow',
  'lime',
  'green',
  'emerald',
  'teal',
  'cyan',
  'sky',
  'blue',
  'indigo',
  'violet',
  'purple',
  'fuchsia',
  'pink',
  'rose'
] as const;

const neutralColors = ['neutral'] as const;

const aliasedColors = [
  'black',
  'white',
  'primary',
  'success',
  'error',
  'warning',
  'base-100',
  'base-200',
  'base-300'
] as const;

type ColorKey = (typeof vizColorKeys)[number] | (typeof neutralColors)[number];

type ColorKeyWithWeight = `${ColorKey}-${(typeof colorWeights)[number]}`;

type AliasedColorKey = (typeof aliasedColors)[number];

const colorWeights = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950] as const;

// export const getVizColorValues = memoize((color: (typeof vizColorKeys)[number]) => {
//   return Object.fromEntries(
//     colorWeights.map(weight => [weight, getThemeColor(`${color}-${weight}`)])
//   );
// });

export const getTailwindThemeValues = () => {
  const getThemeColor = (colorStr: string): string => {
    const styles = getComputedStyle(document.documentElement);
    return styles.getPropertyValue(`--color-${colorStr}`);
  };
  const styles = getComputedStyle(document.documentElement);
  const colorValues = Object.fromEntries(
    [...vizColorKeys, ...neutralColors].flatMap(color => {
      return colorWeights.map(weight => {
        return [`${color}-${weight}`, getThemeColor(`${color}-${weight}`)];
      });
    })
  ) as Record<`${ColorKey}-${(typeof colorWeights)[number]}`, string>;

  const aliasedColorValues = Object.fromEntries(
    aliasedColors.map(color => {
      return [`${color}`, getThemeColor(color)];
    })
  ) as Record<`${(typeof aliasedColors)[number]}`, string>;

  const rootFontSize = parseFloat(styles.fontSize);

  const breakPointToPixel = (breakPoint: BreakPoint) => {
    const initial = styles.getPropertyValue(`--breakpoint-${breakPoint}`).trim();
    if (initial.endsWith('rem')) {
      return parseInt(initial.replace('rem', ''), 10) * rootFontSize;
    }
    return parseInt(initial.replace('px', ''), 10);
  };
  const breakPoints = {
    sm: breakPointToPixel('sm'),
    md: breakPointToPixel('md'),
    lg: breakPointToPixel('lg'),
    xl: breakPointToPixel('xl'),
    '2xl': breakPointToPixel('2xl')
  };
  return {
    fontSans: styles.getPropertyValue('--font-sans').trim(),
    colors: {
      ...colorValues,
      ...aliasedColorValues
    },
    breakPoints
  };
};

export type TailwindThemeValues = ReturnType<typeof getTailwindThemeValues>;

export const getVisXTheme = (theme: TailwindThemeValues, themeMode: ThemeMode) => {
  const isDark = themeMode === 'dark';
  const strokeColor = isDark ? theme.colors['neutral-300'] : theme.colors['neutral-600'];
  return buildVisxChartTheme({
    backgroundColor: isDark ? theme.colors['neutral-900'] : theme.colors['neutral-50'],
    colors: [],
    svgLabelSmall: {
      fontSize: 14,
      fontFamily: theme.fontSans,
      fill: isDark ? theme.colors['neutral-50'] : theme.colors['neutral-800']
    },
    svgLabelBig: {
      fontSize: 16,
      fontFamily: theme.fontSans,
      fontWeight: 'normal',
      fill: isDark ? theme.colors['neutral-50'] : theme.colors['neutral-800'],
      strokeWidth: 0,
      letterSpacing: 'normal'
    },
    xAxisLineStyles: {
      stroke: strokeColor,
      strokeWidth: 1,
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    },
    yAxisLineStyles: {
      stroke: strokeColor,
      strokeWidth: 1,
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    },
    xTickLineStyles: {
      stroke: strokeColor
    },
    yTickLineStyles: {
      stroke: strokeColor
    },
    tickLength: 10,
    gridColor: themeMode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.15)',
    gridColorDark: themeMode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.15)',
    gridStyles: {
      strokeDasharray: '10,5',
      strokeLinecap: 'round',
      strokeLinejoin: 'round'
    }
  });
};

export const getClerkTheme = (theme: TailwindThemeValues, themeMode: ThemeMode): ClerkTheme => {
  const getConvertedThemeColor = (color: ColorKeyWithWeight | AliasedColorKey) => {
    const val = theme.colors[`${color}`];
    if (val.trim().startsWith('oklch(')) return oklchToRgb(val);
    return;
  };
  const contentColor =
    themeMode === 'dark' ? getConvertedThemeColor('white') : getConvertedThemeColor('black');
  return {
    baseTheme: themeMode === 'dark' ? clerkDarkBaseTheme : undefined,
    layout: {
      socialButtonsPlacement: 'bottom',
      showOptionalFields: true
    },
    elements: {
      rootBox: 'h-full w-full',
      cardBox: 'shadow-none h-full justify-between w-full max-w-full items-center',
      card: 'bg-inherit shadow-none border-none max-w-[400px] w-full outline-hidden',
      footer: 'bg-inherit shadow-none border-none pt-0 mt-0 self-stretch'
    },
    variables: {
      colorPrimary: getConvertedThemeColor('primary'),
      colorTextOnPrimaryBackground: getConvertedThemeColor('white'),
      colorDanger: getConvertedThemeColor('error'),
      colorSuccess: getConvertedThemeColor('success'),
      colorWarning: getConvertedThemeColor('warning'),
      colorNeutral:
        themeMode === 'dark'
          ? getConvertedThemeColor('neutral-200')
          : getConvertedThemeColor('neutral-700'),
      colorText: contentColor,
      colorTextSecondary: contentColor,
      colorInputText: contentColor,
      colorBackground: getConvertedThemeColor('base-100'),
      fontFamily: theme.fontSans,
      borderRadius: '0px'
    }
  };
};
