import { createRootRouteWithContext } from '@tanstack/react-router';
import { hidePageLoader, showPageLoader } from 'src/utils';
import RootLayout from '../components/layouts/RootLayout';
import { RouterContext } from './common';

export const rootRoute = createRootRouteWithContext<RouterContext>()({
  component: RootLayout,
  onLeave: () => showPageLoader(),
  beforeLoad: () => hidePageLoader()
});
