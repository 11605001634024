import axios, { AxiosError } from 'axios';
import { atomWithMutation, atomWithQuery, queryClientAtom } from 'jotai-tanstack-query';
import { promptAtom, toastAtom } from '.';
import { atom } from 'jotai';
import { v4 } from 'uuid';

type ReplicationSlot = {
  slot_name: string;
  plugin: string;
  slot_type: string;
  datoid: number;
  database: string;
  temporary: boolean;
  active: boolean;
  active_pid: number;
  xmin: null | string;
  catalog_xmin: string;
  restart_lsn: string;
  confirmed_flush_lsn: string;
  wal_status: string;
  safe_wal_size: null | number;
  two_phase: boolean;
  conflicting: boolean;
};

interface MessageQueueMessage {
  type: string;
  id: string;
  payload?: Record<string, unknown> | null;
  meta: {
    source: string;
    retryCount?: number;
  };
}

export class AdminAtoms {
  private sendTestEmailMutation = atomWithMutation(get => {
    return {
      mutationFn: () => axios.post('/admin/test-email')
    };
  });

  sendTestEmail = atom(null, async (get, set) => {
    const { mutate } = get(this.sendTestEmailMutation);
    await mutate();
    set(toastAtom, { title: 'Test email sent' });
  });

  private sendQueueMessageMutationAtom = atomWithMutation(get => {
    return {
      mutationFn: (payload: MessageQueueMessage) => axios.post('/admin/queue-message', payload)
    };
  });

  sendSanityCheckMessage = atom(null, async (get, set) => {
    const payload = {
      type: 'SANITY_CHECK',
      id: v4(),
      meta: {
        source: 'admin-ui'
      }
    };
    const { mutateAsync } = get(this.sendQueueMessageMutationAtom);
    await mutateAsync(payload);
    set(toastAtom, { title: 'Sanity check message sent' });
  });

  dbMigrations = atomWithQuery<{ executed: string[]; pending: string[] }, AxiosError>(() => ({
    queryKey: ['/admin/db-migrations']
  }));

  migrateDb = atomWithMutation(get => {
    const queryClient = get(queryClientAtom);
    return {
      mutationFn: () => axios.post('/admin/db-migrations/migrate'),
      retry: false,
      retryDelay: 3000,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['/admin/db-migrations']
        });
      }
    };
  });

  replicationSlots = atomWithQuery<ReplicationSlot[], AxiosError>(() => ({
    queryKey: ['/admin/replication-slots']
  }));

  private deleteReplicationSlotMutation = atomWithMutation(get => {
    const queryClient = get(queryClientAtom);
    return {
      mutationFn: async (slotName: string) => {
        await axios.delete(`/admin/replication-slots/${slotName}`);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['/admin/replication-slots']
        });
      }
    };
  });

  deleteReplicationSlot = atom(null, async (get, set, slotName: string) => {
    const confirmed = await set(
      promptAtom,
      `Are you sure you want to delete this replication slot?`
    );
    if (!confirmed) return;
    const { mutateAsync } = get(this.deleteReplicationSlotMutation);
    await mutateAsync(slotName);
    set(toastAtom, { title: 'Replication slot deleted' });
  });
}
