import { UserPreferences, userPreferenceSchema } from 'daydash-data-structures';
import { atom } from 'jotai';
import log from 'src/log';
import { z } from 'zod';
import { coreClientAtom, toastAtom } from '.';
import { AuthAtoms } from './auth.atoms';
import { labelAtoms } from './utils';

export const subscriptionSchema = z.object({
  id: z.string(),
  status: z.enum([
    'active',
    'canceled',
    'incomplete',
    'incomplete_expired',
    'past_due',
    'paused',
    'trialing',
    'unpaid'
  ]),
  customerId: z.string(),
  trialEnd: z.number().nullable()
});

class SubscriptionAtoms {
  constructor(private currentUserAtom: UserAtoms['current']) {}

  current = atom(get => {
    return get(this.currentUserAtom)?.subscription;
  });

  status = atom(get => {
    const subscription = get(this.current);
    return subscription?.status ?? null;
  });

  #trialEnd = atom(get => {
    const subscription = get(this.current);
    return subscription?.trialEnd ? new Date(subscription.trialEnd) : null;
  });

  isTrialing = atom(get => {
    const status = get(this.status);
    return status === 'trialing';
  });

  currentTrial = atom(get => {
    if (!get(this.isTrialing)) return null;
    const trialEnd = get(this.#trialEnd);
    if (!trialEnd) return null;
    return { end: trialEnd };
  });

  isActive = atom(get => {
    const status = get(this.status);
    return status === 'active';
  });

  isInactive = atom(get => {
    const status = get(this.status);
    return status === 'canceled' || status === 'unpaid' || status === 'incomplete_expired';
  });

  isPaused = atom(get => {
    const status = get(this.status);
    return status === 'paused';
  });

  isPaymentWarning = atom(get => {
    const status = get(this.status);
    return status === 'past_due' || status === 'incomplete';
  });
}

@labelAtoms
export class UserAtoms {
  constructor(private authAtoms: AuthAtoms) {}

  current = atom(get => {
    const authUser = get(this.authAtoms.user);
    if (!authUser) return null;
    const { preferences, interests, otherInterests } = z
      .object({
        preferences: userPreferenceSchema.optional().default(userPreferenceSchema.parse({})),
        interests: z.array(z.string()).optional(),
        otherInterests: z.string().optional()
      })
      .parse(authUser.unsafeMetadata);

    const { isAdmin, publicKey, accountSetupComplete, subscription } = z
      .object({
        isAdmin: z.boolean().optional(),
        publicKey: z.string().optional(),
        subscription: subscriptionSchema.optional(),
        accountSetupComplete: z.boolean().optional().default(false)
      })
      .parse(authUser.publicMetadata);

    return {
      id: authUser.id,
      emailAddresses: authUser.emailAddresses.map(email => email.emailAddress),
      firstName: authUser.firstName,
      lastName: authUser.lastName,
      createdAt: authUser.createdAt ? new Date(authUser.createdAt) : null,
      updatedAt: authUser.updatedAt ? new Date(authUser.updatedAt) : null,
      interests,
      otherInterests,
      isAdmin,
      preferences,
      accountSetupComplete,
      publicKey,
      subscription
    };
  });

  updating = atom(false);

  update = atom(
    null,
    async (
      get,
      set,
      payload: {
        firstName?: string | null;
        lastName?: string | null;
        preferences?: UserPreferences;
      }
    ) => {
      set(this.updating, true);
      try {
        const authUser = get(this.authAtoms.user);
        if (!authUser) throw new Error('No user found');
        const {
          firstName = authUser.firstName,
          lastName = authUser.lastName,
          preferences = authUser.unsafeMetadata.preferences
        } = payload;
        await authUser.update({
          firstName: firstName ?? undefined,
          lastName: lastName ?? undefined,
          unsafeMetadata: { preferences: preferences }
        });
        set(toastAtom, { title: 'User updated' });
      } catch (err) {
        console.error(err);
        set(toastAtom, { title: 'Error updating user', description: (err as Error)?.message });
      }
      set(this.updating, false);
    }
  );

  subscription = new SubscriptionAtoms(this.current);

  updateProfileImage = atom(null, async (get, set, file: Blob | File | string) => {
    set(this.updating, true);
    try {
      const authUser = get(this.authAtoms.user);
      if (!authUser) {
        throw new Error('No user found');
      }
      await authUser.setProfileImage({ file });
      set(toastAtom, { title: 'Profile Image updated' });
    } catch (err) {
      log.error(err);
      set(toastAtom, {
        title: 'Error updating profile image',
        description: (err as Error)?.message
      });
    }
    set(this.updating, false);
  });

  removeProfileImage = atom(null, async (get, set) => {
    set(this.updating, true);
    try {
      const authUser = get(this.authAtoms.user);
      if (!authUser) {
        throw new Error('No user found');
      }
      await authUser.setProfileImage({ file: null });
      set(toastAtom, { title: 'Profile Image removed' });
    } catch (err) {
      log.error(err);
      set(toastAtom, {
        title: 'Error removing profile image',
        description: (err as Error)?.message
      });
    }
    set(this.updating, false);
  });

  profileImage = atom(get => {
    const authUser = get(this.authAtoms.user);
    if (!authUser) {
      return null;
    }
    return authUser.imageUrl;
  });

  firstName = atom(get => {
    const user = get(this.current);
    return user?.firstName ?? null;
  });

  initializingUserKeys = atom(false);

  initializeUserKeys = atom(null, async (get, set) => {
    const authUser = get(this.authAtoms.user);
    if (!authUser) throw new Error('No user found');
    const coreClient = await get(coreClientAtom);
    const { publicKey, recoveryCode } = await coreClient.initializeUserKeys();

    const file = new Blob([recoveryCode], { type: 'text/plain' });
    const url = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'daydash_recovery_code.txt';
    link.click();
    URL.revokeObjectURL(url);

    return { publicKey, recoveryCode };
  });
}
