import { assertNever } from 'axil-utils';
export * from './dataSourceConfig';
export * from './interpolation';
export { schema as widgetSchema, dataSourceConfigVal as dataSourceConfigSchema, chartConfigVal as chartConfigSchema, layerConfigVal as layerConfigSchema } from './validation';
/**
 * How to add widgets
 *
 * 1. Add the structure below.
 *  - Implement the latest type and the type that represents all of the versions
 *  - Add the getLatestType function
 * 2. Add the widget to WidgetTypeToLatest, getLatestWidgetOfType, and the overall Widget type
 * 3. Add the widget label to widgetTypes
 * 4. In the validation module, add the widget type to the schema so that it matches the type structure
 * 5. In apps/daydash-ui/src/components/Widget/config.ts, add and icon and placeholder components for Edit and View
 * 6. Implement the Edit module
 * 7. Implement the View module
 */
export const scaleOptions = [
    { label: 'Linear', value: 'linear' },
    { label: 'Log', value: 'log' }
];
export const getLatestLineChartType = (widget) => {
    if (widget.v === 1)
        return widget;
    // Add migration steps here
    throw new Error('Unsupported widget version of: ' + widget.v);
};
export const getLatestAreaChartType = (widget) => {
    if (widget.v === 1)
        return widget;
    // Add migration steps here
    throw new Error('Unsupported widget version of: ' + widget.v);
};
export const getLatestBarChartType = (widget) => {
    if (widget.v === 1)
        return widget;
    // Add migration steps here
    throw new Error('Unsupported widget version of: ' + widget.v);
};
export const getLatestCatBarChartType = (widget) => {
    if (widget.v === 1)
        return widget;
    // Add migration steps here
    throw new Error('Unsupported widget version of: ' + widget.v);
};
export const getLatestDataTableType = (widget) => {
    if (widget.v === 1)
        return widget;
    // Add migration steps here
    throw new Error('Unsupported widget version of: ' + widget.v);
};
export const getLatestSingleValueType = (widget) => {
    if (widget.v === 1 || !widget.v)
        return widget;
    // Add migration steps here
    throw new Error('Unsupported widget version of: ' + widget.v);
};
export const getLatestPieChartType = (widget) => {
    if (widget.v === 1)
        return widget;
    // Add migration steps here
    throw new Error('Unsupported widget version of: ' + widget.v);
};
export const getLatestSankeyChartType = (widget) => {
    if (widget.v === 1)
        return widget;
    // Add migration steps here
    throw new Error('Unsupported widget version of: ' + widget.v);
};
export const getLatestNoteType = (widget) => {
    if (widget.v === 1)
        return widget;
    // Add migration steps here
    throw new Error('Unsupported widget version of: ' + widget.v);
};
export const getLatestWidgetOfType = (widget) => {
    // Too extreme for Typescript so we need to cast to R for each
    if (widget.type === 'LineChart')
        return getLatestLineChartType(widget);
    if (widget.type === 'AreaChart')
        return getLatestAreaChartType(widget);
    if (widget.type === 'BarChart')
        return getLatestBarChartType(widget);
    if (widget.type === 'CatBarChart')
        return getLatestCatBarChartType(widget);
    if (widget.type === 'DataTable')
        return getLatestDataTableType(widget);
    if (widget.type === 'SingleValue')
        return getLatestSingleValueType(widget);
    if (widget.type === 'PieChart')
        return getLatestPieChartType(widget);
    if (widget.type === 'SankeyChart')
        return getLatestSankeyChartType(widget);
    if (widget.type === 'Note')
        return getLatestNoteType(widget);
    assertNever(widget);
    throw new Error('Invalid widget type of ' + widget.type);
};
export const widgetTypes = [
    { type: 'LineChart', label: 'Line Chart' },
    { type: 'AreaChart', label: 'Area Chart' },
    { type: 'BarChart', label: 'Bar Chart' },
    { type: 'CatBarChart', label: 'Categorical Bar Chart' },
    { type: 'DataTable', label: 'Data Table' },
    { type: 'SingleValue', label: 'Single Value' },
    { type: 'PieChart', label: 'Pie Chart' },
    { type: 'SankeyChart', label: 'Sankey Chart' },
    { type: 'Note', label: 'Note' }
];
export const isWidgetTypeWithDataSource = (type) => {
    return type !== 'Note';
};
