import { type Atom, type WritableAtom, atom } from 'jotai';
import { SetStateAction } from 'react';
import { observe } from 'jotai-effect';
import { isObject } from 'lodash';

type Getter = <Value>(atom: Atom<Value>) => Value;

/**
 * A utility function to create an atom that throws an error if the value is not present.
 * This helps remove a lot of null checks that are largely unnecessary after the value is set.
 */
export const atomWithUnwrap = <T>(
  init: T | null | Atom<T | null>['read'],
  name: string = 'unknown'
) => {
  const initialAtom = atom<typeof init>(init);
  if (process.env.NODE_ENV === 'development') {
    initialAtom.debugLabel = `initialWrapped("${name}")Atom`;
  }
  const read = (get: Getter): T => {
    const value = get(initialAtom) as T | null;
    if (value == null) {
      throw new Error(`${name} atom is not present`);
    }
    return value;
  };
  type R = typeof init extends Atom<T | null>['read'] ? Atom<T> : WritableAtom<T, [T], void>;
  let unwrappedAtom: R;
  if (typeof init === 'function') {
    unwrappedAtom = atom(read) as R;
  } else {
    unwrappedAtom = atom(read, (_get, set, value: T) => {
      set(initialAtom, value);
    }) as WritableAtom<T, [T], void>;
  }
  if (process.env.NODE_ENV === 'development') {
    initialAtom.debugLabel = `initialWrapped("${name}")Atom`;
    unwrappedAtom.debugLabel = `unwrapped("${name}")Atom`;
  }
  return unwrappedAtom;
};

export default function atomWithDebounce<T>(
  initialValue: T,
  delayMilliseconds = 50,
  shouldDebounceOnReset = false
) {
  const prevTimeoutAtom = atom<ReturnType<typeof setTimeout> | undefined>(undefined);

  // DO NOT EXPORT currentValueAtom as using this atom to set state can cause
  // inconsistent state between currentValueAtom and debouncedValueAtom
  const _currentValueAtom = atom(initialValue);
  const isDebouncingAtom = atom(false);

  const debouncedValueAtom = atom(initialValue, (get, set, update: SetStateAction<T>) => {
    clearTimeout(get(prevTimeoutAtom));

    const prevValue = get(_currentValueAtom);
    const nextValue = typeof update === 'function' ? (update as (prev: T) => T)(prevValue) : update;

    const onDebounceStart = () => {
      set(_currentValueAtom, nextValue);
      set(isDebouncingAtom, true);
    };

    const onDebounceEnd = () => {
      set(debouncedValueAtom, nextValue);
      set(isDebouncingAtom, false);
    };

    onDebounceStart();

    if (!shouldDebounceOnReset && nextValue === initialValue) {
      onDebounceEnd();
      return;
    }

    const nextTimeoutId = setTimeout(() => {
      onDebounceEnd();
    }, delayMilliseconds);

    // set previous timeout atom in case it needs to get cleared
    set(prevTimeoutAtom, nextTimeoutId);
  });
  debouncedValueAtom.debugLabel = 'debouncedValueAtom';

  // exported atom setter to clear timeout if needed
  const clearTimeoutAtom = atom(null, (get, set, _arg) => {
    clearTimeout(get(prevTimeoutAtom));
    set(isDebouncingAtom, false);
  });

  return {
    currentValueAtom: atom(get => get(_currentValueAtom)),
    isDebouncingAtom,
    clearTimeoutAtom,
    debouncedValueAtom
  };
}

export const matchMediaAtom = (query: string) => {
  const mediaAtom = atom(false);
  mediaAtom.onMount = set => {
    const mediaQuery = window.matchMedia(query);
    const syncMatches = () => {
      set(mediaQuery.matches);
    };
    syncMatches();
    mediaQuery.addEventListener('change', syncMatches);
    return () => {
      mediaQuery.removeEventListener('change', syncMatches);
    };
  };
  return mediaAtom;
};

/**
 * A utility function easily gather all of the observables so we can then register them all when we create the store
 */
export type Effect = Parameters<typeof observe>[0];

/**
 * Note: I tried to use a decorator but it doesn't really work because we rely on properties. Thinking through this later
 */

export const labelAtoms = (instance: any, context: ClassDecoratorContext) => {
  return class extends instance {
    constructor(...args: any[]) {
      super(...args);
      Object.getOwnPropertyNames(this).forEach(key => {
        const value = this[key];
        // Basically just check to make sure its an atom
        if (value && isObject(value) && 'read' in value) {
          (value as any).debugLabel = `${context.name}.${key}`;
        }
      });
    }
  } as any;
};
