import { useTransition } from '@react-spring/web';
import { usePrefersReducedMotion } from 'axil-web-ui';
import { BreakPoint } from 'src/theme';

export function useSlideTransition<I>(item: I, forward: boolean) {
  return useTransition<I, React.CSSProperties>(item, {
    keys: null,
    initial: null,
    // Uncomment to debug awkward transitions
    // config: { mass: 1, tension: 500, friction: 50, duration: 1000000 },
    from: { opacity: 0, transform: `translate3d(${forward ? '' : '-'}100%,0,0)` },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    leave: {
      opacity: 0,
      transform: `translate3d(${forward ? '-' : ''}100%,0,0)`,
      height: '100%',
      width: '100%',
      position: 'absolute'
    },
    immediate: usePrefersReducedMotion()
  });
}

// Mainly copied from grommet
export const getBreakpointSize = (
  containerWidth: number,
  breakPoints: Record<BreakPoint, number>
) => {
  // Sorted in reverse order because breakpoints are based on screen max
  const sortedBreakpoints = (Object.keys(breakPoints) as Array<BreakPoint>).sort((a, b) => {
    return breakPoints[b] - breakPoints[a];
  });
  // the last breakpoint on the sorted array should have
  // no windowWidth boundaries
  const lastBreakpoint = sortedBreakpoints.at(-1)!;
  const result = sortedBreakpoints.find(name => {
    const breakpoint = breakPoints[name];
    return breakpoint <= containerWidth ? name : false;
  });
  return result || lastBreakpoint;
};
