import { z } from 'zod';
import { Units } from '.';
const unitPreferenceSchema = z.object({
    temperature: z.nativeEnum(Units.Temperature).optional().default(Units.Temperature.F),
    energy: z.nativeEnum(Units.Energy).optional().default(Units.Energy.kcal),
    liquid: z.nativeEnum(Units.Liquid).optional().default(Units.Liquid.floz),
    distance: z.nativeEnum(Units.Distance).optional().default(Units.Distance.mi),
    mass: z.nativeEnum(Units.Mass).optional().default(Units.Mass.lb)
});
export const userPreferenceSchema = z.object({
    v: z.literal(0).optional().default(0),
    // TODO: Try to figure out a way to assert that the z enum has all the types of the preferences enum
    appearance: z.enum(['dark', 'light', 'system']).optional().default('system'),
    units: unitPreferenceSchema.optional().default(unitPreferenceSchema.parse({}))
});
