import { useMutation, useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { UserFile } from '../../types/entities';
import { useAtomValue } from 'jotai';
import { coreClientAtom } from 'src/state';

export const useUserFile = (id: string) => {
  const coreClient = useAtomValue(coreClientAtom);
  return useQuery(coreClient.getQueryOptions.getUserFile(id));
};

export const useUpsertUserFile = () => {
  return useMutation<
    AxiosResponse<UserFile>,
    unknown,
    {
      id?: string;
      file: File;
    }
  >({
    mutationFn: payload => {
      const { file, id } = payload;
      const formData = new FormData();
      formData.set('file', file);
      if (id) return axios.put(`/user-file/${id}`, formData);
      return axios.post('/user-file', formData);
    }
  });
};
