import { DeepKeys, FormApi, FormState, ValidationError } from '@tanstack/react-form';
import { isNotEmpty } from 'axil-utils';
import {
  Widget,
  dataSourceConfigSchema,
  getDataSourceConfigLayers,
  isWidgetTypeWithDataSource,
  widgetSchema
} from 'daydash-data-structures';
import { atom } from 'jotai';
import { atomWithQuery, atomWithSuspenseQuery, queryClientAtom } from 'jotai-tanstack-query';
import lget from 'lodash/get';
import { ZodRawShape } from 'zod';
import { coreClientAtom, unwrappedCoreClientAtom } from '.';
import { UserAtoms } from './user.atoms';
import { DataSourceAtoms } from './dataSources.atoms';
import { Effect, labelAtoms } from './utils';
import { WidgetFormApi } from 'src/components/Widget/WidgetForm/form';

const STEPS = ['type-picker', 'data-source-config', 'finalize'] as const;
export type STEP = (typeof STEPS)[number];

@labelAtoms
export class WidgetFormAtoms {
  constructor(
    private dataSourceAtoms: DataSourceAtoms,
    private userAtoms: UserAtoms
  ) {}

  widgetForm = atom<WidgetFormApi | null>(null);

  widgetFormState = atom<WidgetFormApi['store']['state'] | null>();

  widgetFormInitialized = atom(get => Boolean(get(this.widgetForm)));

  initializeWidgetForm = atom(null, (get, set, form: WidgetFormApi) => {
    set(this.widgetForm, form);
    // Initialize the state. The listener will be set in the sync effect
    set(this.widgetFormState, form.store.state);
    // Set to finalize by default if we have default values
    const editing = Boolean(form.options.defaultValues?.type);
    set(this.currentWidgetFormStep, editing ? 'finalize' : 'type-picker');
  });

  cleanupWidgetForm = atom(null, (get, set) => {
    const form = get(this.widgetForm);
    if (!form) return;
    set(this.widgetForm, null);
    set(this.widgetFormState, null);
  });

  private syncWidgetFormState: Effect = (get, set) => {
    const widgetForm = get(this.widgetForm);
    return widgetForm?.store.subscribe(() => {
      set(this.widgetFormState, widgetForm.store.state);
    });
  };

  widgetFormDirty = atom(get => get(this.widgetFormState)?.isDirty ?? null);

  currentType = atom(get => get(this.widgetFormState)?.values.type ?? null);

  dataSourceFieldPath = atom(get => {
    const currentTypeSchema = get(this.currentWidgetTypeSchema);
    if (!currentTypeSchema) return null;
    return (currentTypeSchema as ZodRawShape).chartConfig ? 'chartConfig.dataSource' : 'dataSource';
  });

  private currentDataSourceConfig = atom(get => {
    const path = get(this.dataSourceFieldPath);
    if (!path) return null;
    return lget(get(this.widgetFormState)?.values, path);
  });

  private currentDataSourceLayers = atom(get => {
    const config = get(this.currentDataSourceConfig);
    if (!config) return null;
    const parsed = dataSourceConfigSchema.safeParse(config);
    if (!parsed.success) return null;
    return getDataSourceConfigLayers(parsed.data);
  });

  private dataSourceQueryValid = atom(get => {
    const dataSourceErrors: ValidationError[] = [];
    const state = get(this.widgetFormState);
    const dataSourceFieldPath = get(this.dataSourceFieldPath);
    if (!state || !dataSourceFieldPath) return null;
    Object.entries(state.fieldMeta).forEach(([key, value]) => {
      if (key.startsWith(dataSourceFieldPath) && value.errors && value.errors.length > 0) {
        dataSourceErrors.push(...value.errors);
      }
    });
    return dataSourceErrors.length === 0;
  });

  currentDataSourceSectionData = atomWithSuspenseQuery(get => {
    const coreClient = get(unwrappedCoreClientAtom);
    const currentUser = get(this.userAtoms.current);
    if (!currentUser) throw new Error('User not found');
    if (!coreClient) throw new Error('Core client not found');
    const layers = get(this.currentDataSourceLayers);
    // Make sure the query is valid before proceeding
    const queryValid = get(this.dataSourceQueryValid);
    return coreClient.getQueryOptions.queryDataPoints({
      layerConfig: queryValid && layers ? layers : null,
      unitPreferences: currentUser.preferences.units,
      selected: 'all',
      order: null
    });
  });

  dataSourceFieldsValid = atom(get => {
    const fieldPath = get(this.dataSourceFieldPath);
    const errorMap = get(this.widgetFormState)?.errorMap;
    return Boolean(fieldPath && !lget(errorMap, fieldPath));
  });

  availableSteps = atom(get => {
    const dataSourceFieldsValid = get(this.dataSourceFieldsValid);
    const currentType = get(this.currentType);
    return [
      'type-picker' as const,
      currentType && isWidgetTypeWithDataSource(currentType)
        ? ('data-source-config' as const)
        : null,
      currentType &&
      ((isWidgetTypeWithDataSource(currentType) && dataSourceFieldsValid) ||
        !isWidgetTypeWithDataSource(currentType))
        ? ('finalize' as const)
        : null
    ].filter(isNotEmpty);
  });

  currentDataSourceId = atom(get => {
    const layers = get(this.currentDataSourceLayers);
    return layers?.[0].dataSourceId ?? null;
  });

  currentDataSource = atom(get => {
    const baseDataSourceId = get(this.currentDataSourceId);
    if (!baseDataSourceId) return null;
    const { data: list } = get(this.dataSourceAtoms.list);
    return list?.find(ds => ds.id === baseDataSourceId) ?? null;
  });

  currentDataSourceSection = atom(async get => {
    const dataSourceId = get(this.currentDataSourceId);
    const sectionId = get(this.currentDataSourceLayers)?.[0].sectionId;
    if (!dataSourceId || !sectionId) return null;
    const coreClient = await get(coreClientAtom);
    const sections = await get(queryClientAtom).fetchQuery(
      coreClient.getQueryOptions.getDataSourceSectionsForDataSource(dataSourceId)
    );
    return sections?.find(s => s.id === sectionId) ?? null;
  });

  currentDataSourceDataFields = atom(async get => {
    const { data } = await get(this.currentDataSourceSectionData);
    return data.fields;
  });

  currentGuessedColorScheme = atom(async get => {
    const dataSourceName = get(this.currentDataSource)?.name;
    const sectionName = (await get(this.currentDataSourceSection))?.name;
    const fields = await get(this.currentDataSourceDataFields);
    if (!dataSourceName || !sectionName || !fields) return null;
    const searchParams = new URLSearchParams();
    searchParams.append('dataSourceName', dataSourceName);
    searchParams.append('sectionName', sectionName);
    fields?.forEach(field => searchParams.append('fieldLabels', field.label));
    const queryClient = get(queryClientAtom);
    return queryClient.fetchQuery<string[]>({
      queryKey: [`/ui-intelligence/guess-color-scheme?${searchParams.toString()}`]
    });
  });

  updateGuessedColorScheme = atom(null, async (get, set) => {
    const form = get(this.widgetForm);
    if (!form) throw new Error('Form not found');
    const data = await get(this.currentGuessedColorScheme);
    const fieldPath = get(this.dataSourceFieldPath);
    if (!fieldPath) throw new Error('Field path not found');
    form.setFieldValue(`${fieldPath}.initialColors`, data ?? []);
  });

  currentWidgetFormStep = atom<STEP>('type-picker');

  currentWidgetTypeSchema = atom(get => {
    const currentType = get(this.currentType);
    if (!currentType) return null;
    const schema = widgetSchema.options.find(o => o.shape.type.value === currentType)?.shape;
    if (!schema) throw new Error(`Schema not found for type ${currentType}`);
    return schema;
  });

  private nextStep = atom(null, (get, set) => {
    const nextSteps = get(this.availableSteps);
    set(
      this.currentWidgetFormStep,
      nextSteps[nextSteps.indexOf(get(this.currentWidgetFormStep)) + 1]
    );
  });

  updateType = atom(null, (get, set, newType: Widget['type']) => {
    const form = get(this.widgetForm);
    if (!form) throw new Error('Form not found');
    if (!newType) return;
    const initialDataSourcePath = get(this.dataSourceFieldPath);
    form.setFieldValue('type', newType);
    const currentTypeSchema = get(this.currentWidgetTypeSchema);
    if (!currentTypeSchema) throw new Error('Schema not found');
    // Get default v type
    form.setFieldValue('v', currentTypeSchema.v.parse(undefined), { dontUpdateMeta: true });
    // Reset the data source fields as necessary
    const newDataSourcePath = get(this.dataSourceFieldPath);
    if (initialDataSourcePath && newDataSourcePath && initialDataSourcePath !== newDataSourcePath) {
      form.setFieldValue(newDataSourcePath, form.getFieldValue(initialDataSourcePath));
    }
    // Now, reset all of the fields that weren't touched as we can set new defaults
    // (think horizontal defaulting to true for cat bar chart but not bar chart)
    Object.entries(form.state.fieldMeta).forEach(([key, meta]) => {
      if (key === 'type' || key === 'v' || (newDataSourcePath && key.startsWith(newDataSourcePath)))
        return;
      if (!meta.isTouched) form.deleteField(key as DeepKeys<Widget>);
    });
    set(this.nextStep);
  });

  effects: Effect[] = [this.syncWidgetFormState];
}
