import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import log from 'src/log';
import { Effect, labelAtoms } from './utils';
import { UIAtoms } from './ui.atoms';
import { HistoryAtoms } from './history.atoms';

@labelAtoms
export class SidebarAtoms {
  constructor(
    private uiAtoms: UIAtoms,
    private historyAtoms: HistoryAtoms
  ) {}

  sidebar = atom<{
    expanded: boolean;
    open: boolean;
    hovering: boolean;
  }>({
    expanded: true,
    open: false,
    hovering: false
  });

  canExpand = atom(get => {
    return get(this.uiAtoms.pageSize) !== 'sm';
  });

  expandedPreference = atomWithStorage<boolean>('sidebar-expanded', true, undefined, {
    getOnInit: true
  });

  private sidebarEffect: Effect = (get, set) => {
    const currentExpanded = get(this.sidebar).expanded;
    const canExpand = get(this.canExpand);
    const expandedPreference = get(this.expandedPreference);
    let newExpanded: boolean;
    if (canExpand && expandedPreference) newExpanded = true;
    else if (!canExpand) newExpanded = false;
    else if (!expandedPreference) newExpanded = false;
    else newExpanded = currentExpanded;
    set(this.sidebar, prev => ({ ...prev, expanded: newExpanded }));
  };

  private sidebarCloseOnPathChange: Effect = (get, set) => {
    get(this.historyAtoms.pathname); // Triggers the update
    if (get.peek(this.sidebar).open) set(this.close);
  };

  expand = atom(null, (get, set) => {
    if (!get(this.canExpand)) {
      log.error('Sidebar cannot expand');
      return;
    }
    set(this.expandedPreference, true);
    set(this.sidebar, { hovering: false, expanded: true, open: false });
  });

  collapse = atom(null, (get, set) => {
    set(this.expandedPreference, false);
    set(this.sidebar, { hovering: false, expanded: false, open: false });
  });

  open = atom(null, (get, set) => {
    if (get(this.sidebar).expanded) {
      log.error('Sidebar is already expanded, cannot open');
      return;
    }
    set(this.sidebar, { expanded: false, hovering: false, open: true });
  });

  close = atom(null, (get, set) => {
    if (get(this.sidebar).expanded) {
      log.error('Sidebar is expanded, cannot close. Use a collapse instead');
      return;
    }
    set(this.sidebar, { expanded: false, hovering: false, open: false });
  });

  hover = atom(null, (get, set, hovering: boolean) => {
    set(this.sidebar, prev => ({ ...prev, hovering }));
  });

  effects: Effect[] = [this.sidebarEffect, this.sidebarCloseOnPathChange];
}
