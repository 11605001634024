import { createRoute, redirect } from '@tanstack/react-router';
import WelcomePage from 'src/components/pages/AccountSetup/WelcomePage';
import { rootRoute } from './root';
import { getCurrentUserFromRouteContext } from './common';
import InterestsPage from 'src/components/pages/AccountSetup/InterestsPage';
import EncryptionKeySetupPage from 'src/components/pages/AccountSetup/EncryptionKeySetupPage';
import SubscriptionSetupPage from 'src/components/pages/AccountSetup/SubscriptionSetupPage';
import AccountSetupPage from 'src/components/pages/AccountSetup/AccountSetupPage';
import SuccessPage from 'src/components/pages/AccountSetup/SuccessPage';

const indexRoute = createRoute({
  getParentRoute: () => accountSetupRoute,
  path: '/account-setup',
  beforeLoad: async () => {
    throw redirect({ to: '/account-setup/welcome' });
  }
});

const welcomeRoute = createRoute({
  getParentRoute: () => accountSetupRoute,
  path: '/account-setup/welcome',
  component: WelcomePage
});

const interestsRoute = createRoute({
  getParentRoute: () => accountSetupRoute,
  path: '/account-setup/interests',
  component: InterestsPage
});

const encryptionKeySetupRoute = createRoute({
  getParentRoute: () => accountSetupRoute,
  path: '/account-setup/encryption-key',
  component: EncryptionKeySetupPage
});

const subscriptionSetupRoute = createRoute({
  getParentRoute: () => accountSetupRoute,
  path: '/account-setup/subscription',
  component: SubscriptionSetupPage
});

const successRoute = createRoute({
  getParentRoute: () => accountSetupRoute,
  path: '/account-setup/success',
  component: SuccessPage
});

const accountSetupRoute = createRoute({
  getParentRoute: () => rootRoute,
  component: AccountSetupPage,
  id: 'account-setup',
  beforeLoad: async ({ location, context }) => {
    const user = await getCurrentUserFromRouteContext(context);
    if (!user) {
      throw redirect({
        to: '/login',
        search: location.href && location.href !== '/' ? { redirect: location.href } : {}
      });
    }
    // Already set up, just go back to the index route
    if (user.accountSetupComplete) {
      throw redirect({
        to: '/'
      });
    }
  }
});

export const getAccountSetupRouteTree = () => {
  return accountSetupRoute.addChildren([
    indexRoute,
    welcomeRoute,
    interestsRoute,
    encryptionKeySetupRoute,
    subscriptionSetupRoute,
    successRoute
  ]);
};
