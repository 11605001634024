import { isWebworker } from './utils';

const patchInEnvToServiceURL = (serviceUrl: string) => {
  const initialServiceURL = new URL(serviceUrl);
  const initialServiceURLParts = initialServiceURL.hostname.split('.');
  const location = isWebworker() ? self.location : window.location;
  const appHostNameParts = location.hostname.split('.');
  // It is in a non prod environment. Patch in the env
  if (appHostNameParts.length === 4 && initialServiceURLParts.length === 3) {
    const env = appHostNameParts[1];
    initialServiceURL.hostname = `${initialServiceURLParts[0]}.${env}.${initialServiceURLParts[1]}.${initialServiceURLParts[2]}`;
  } else if (
    initialServiceURL.hostname === 'localhost' &&
    location.hostname.startsWith('192.168.')
  ) {
    // Patch in the current location hostname if localhost, in case you are running on a local simulator
    initialServiceURL.hostname = location.hostname;
  }
  return initialServiceURL.toString().replace(/\/$/, ''); // Remove trailing slash
};

export const getServiceURL = () => patchInEnvToServiceURL(import.meta.env.VITE_SERVICE_URL);
