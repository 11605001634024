import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

export function useAccountSetup() {
  return useMutation({
    mutationFn: async (values: {
      interests: string[];
      otherInterests: string;
      publicKey: string;
    }) => axios.post('/account/setup', values)
  });
}
