import { atom, WritableAtom } from 'jotai';
import { initialCoreClientAtom, coreClientAtom, promptAtom, toastAtom } from '.';
import { AuthAtoms } from './auth.atoms';
import { Effect, labelAtoms } from './utils';
import { HistoryAtoms } from './history.atoms';
import { atomWithRefresh, atomWithStorage, unwrap } from 'jotai/utils';
import { UserAtoms } from './user.atoms';
import { z } from 'zod';
import log from 'src/log';

type SettingUpDeviceInfo = {
  devicePublicKey: string;
  encryptedPrivateKey?: string;
};

@labelAtoms
class DeviceKeyTransferAtoms {
  #unwrappedDeviceSetup: WritableAtom<boolean | undefined, [], void>;
  constructor(
    private authAtoms: AuthAtoms,
    private isDeviceSetup: WritableAtom<Promise<boolean | undefined>, [], void>,
    private finishAtom: WritableAtom<null, [], Promise<void>>
  ) {
    this.#unwrappedDeviceSetup = unwrap(this.isDeviceSetup);
  }

  /**
   * General
   */
  // A once off keypair just for transferring the user's encryption key in a secure way
  #deviceSetupKeyPair = atomWithStorage<{ publicKey: string; privateKey: string } | null>(
    'deviceSetupKeyPair',
    null
  );

  // The current device setup info
  #currentSettingUpDeviceInfo = atom((get): Record<string, SettingUpDeviceInfo> | null => {
    const user = get(this.authAtoms.user);
    if (!user) return null;
    return (
      z
        .object({
          settingUpDevice: z
            .record(
              z.string(),
              z.object({
                devicePublicKey: z.string(),
                encryptedPrivateKey: z.string().optional()
              })
            )
            .optional()
        })
        .parse(user.unsafeMetadata).settingUpDevice ?? null
    );
  });

  // A setter for updating device setup session info
  #setSessionDeviceSetup = atom(
    null,
    async (get, set, sessionId: string, info: SettingUpDeviceInfo | null) => {
      const user = get(this.authAtoms.user);
      if (!user) throw new Error('No user');
      const current = get(this.#currentSettingUpDeviceInfo);
      const updated = {
        ...current,
        [sessionId]: info
      };
      // Completely delete it so null doesn't populate the object
      if (!info) delete updated[sessionId];
      await user.update({
        unsafeMetadata: {
          ...user.unsafeMetadata,
          settingUpDevice: updated
        }
      });
    }
  );

  /**
   * Current Device
   */
  start = atom(null, async (get, set) => {
    const coreClient = await get(coreClientAtom);
    const { publicKey, privateKey } = await coreClient.getKeyPairForDeviceSetup();
    await set(this.#currentSessionSetupInfo, { devicePublicKey: publicKey });
    set(this.#deviceSetupKeyPair, { publicKey, privateKey });
  });

  #currentSessionSetupInfo = atom(
    get => {
      const session = get(this.authAtoms.session);
      if (!session) return null;
      return get(this.#currentSettingUpDeviceInfo)?.[session.id] ?? null;
    },
    async (get, set, info: SettingUpDeviceInfo | null) => {
      const sessionid = get(this.authAtoms.session)?.id;
      if (!sessionid) throw new Error('No session id');
      set(this.#setSessionDeviceSetup, sessionid, info);
    }
  );

  #isSettingUpCurrentDevice = atom(get => {
    return Boolean(get(this.#currentSessionSetupInfo));
  });

  #setPrivateKeyFromOtherDevice = atom(null, async (get, set) => {
    const confirmed = await set(
      promptAtom,
      'Another device is trying to set up your encryption key. Are you sure you want to proceed?'
    );
    const deviceSetupKeyPair = get(this.#deviceSetupKeyPair);
    if (!deviceSetupKeyPair) throw new Error('No key pair');
    // If the user doesn't confirm, unset the encrypted private key to accept from another session.
    if (!confirmed) {
      const current = { ...get(this.#currentSessionSetupInfo) };
      delete current.encryptedPrivateKey;
      await set(this.#currentSessionSetupInfo, current as SettingUpDeviceInfo);
      return;
    }
    try {
      const coreClient = await get(coreClientAtom);
      const currentDeviceSetup = get(this.#currentSessionSetupInfo);
      const encryptedPrivateKey = currentDeviceSetup?.encryptedPrivateKey;
      if (!encryptedPrivateKey) throw new Error('No encrypted private key');
      await coreClient.setPrivateKeyFromDeviceSetup(encryptedPrivateKey, {
        devicePublicKey: deviceSetupKeyPair.publicKey,
        devicePrivateKey: deviceSetupKeyPair.privateKey
      });
      set(this.finishAtom); // Call the parent to wrap things up
    } catch (err) {
      console.error(err);
      set(toastAtom, { title: 'There was an error setting up your private key' });
    }
  });

  // If we get an encrypted private key from another device, lets prompt the user to accept it
  #retrievePrivateKeyEffect: Effect = (get, set) => {
    if (!get(this.#isSettingUpCurrentDevice)) return;
    const currentDeviceSetup = get(this.#currentSessionSetupInfo);
    if (currentDeviceSetup?.encryptedPrivateKey) {
      set(this.#setPrivateKeyFromOtherDevice);
    }
  };

  /**
   * Other device Handling
   *
   * TODO: In the future we could support multiple sessions, but for now just do them in order
   */
  #otherDeviceSetupSessionId = atom(get => {
    const session = get(this.authAtoms.session);
    if (!session) return null;
    const currentSetup = get(this.#currentSettingUpDeviceInfo);
    if (!currentSetup) return null;
    return (
      Object.entries(currentSetup).find(([sessionId]) => sessionId !== session.id)?.[0] ?? null
    );
  });

  #otherDeviceSetupInfo = atom(
    get => {
      const sessionId = get(this.#otherDeviceSetupSessionId);
      if (!sessionId) return null;
      return get(this.#currentSettingUpDeviceInfo)?.[sessionId] ?? null;
    },
    async (get, set, info: SettingUpDeviceInfo | null) => {
      const sessionid = get(this.#otherDeviceSetupSessionId);
      if (!sessionid) throw new Error('No session id');
      set(this.#setSessionDeviceSetup, sessionid, info);
    }
  );

  #otherDeviceNeedsKey = atom(get => {
    const other = get(this.#otherDeviceSetupInfo);
    return Boolean(other && !other.encryptedPrivateKey);
  });

  // Acknowledging the other session or not
  #acknowledgeOtherDeviceSetup = atom<string | null>(null);

  #setUpOtherDevice = atom(null, async (get, set) => {
    set(this.#acknowledgeOtherDeviceSetup, get(this.#otherDeviceSetupSessionId));
    const confirmed = await set(
      promptAtom,
      'Another device is attempting to log in. Do you want to send your encryption key to the other device?',
      'Please contact support at support@daydash.io if you did not initiate this.'
    );
    if (!confirmed) return;
    const devicePublicKey = get(this.#otherDeviceSetupInfo)?.devicePublicKey;
    if (!devicePublicKey) throw new Error('No device public key');
    const coreClient = await get(coreClientAtom);
    // The user's private key encrypted with the public key of the other device's set up attempt
    const encryptedPrivateKey =
      await coreClient.getEncryptedPrivateKeyForDeviceSetup(devicePublicKey);
    const user = get(this.authAtoms.user);
    if (!user) throw new Error('No user');
    // Update the user's metadata to include the encrypted private key for the other device
    const current = { ...get(this.#otherDeviceSetupInfo) };
    current.encryptedPrivateKey = encryptedPrivateKey;
    set(this.#otherDeviceSetupInfo, current as SettingUpDeviceInfo);
    set(toastAtom, { title: 'Your private key has been sent to the other device' });
  });

  // Auto launch the prompt when another device is setting up
  #sendPrivateKeyToOtherDeviceEffect: Effect = (get, set) => {
    if (
      get(this.#otherDeviceNeedsKey) &&
      !get(this.#acknowledgeOtherDeviceSetup) &&
      get(this.#unwrappedDeviceSetup)
    ) {
      set(this.#setUpOtherDevice);
    }
  };

  /**
   * Cleanup and effects
   */
  clear = atom(null, async (get, set) => {
    await set(this.#currentSessionSetupInfo, null);
    set(this.#deviceSetupKeyPair, null);
  });

  #clearSessions = atom(null, async (get, set, sessionIds: string[]) => {
    for (const sessionId of sessionIds) {
      await set(this.#setSessionDeviceSetup, sessionId, null);
    }
  });
  #autoClearOldSessionsEffect: Effect = (get, set) => {
    const current = get(this.#currentSettingUpDeviceInfo);
    if (!current) return;
    const sessions = get(this.authAtoms.userSessions);
    const sessionIds = sessions.map(s => s.id);
    const oldSessions = Object.keys(current).filter(sessionId => !sessionIds.includes(sessionId));
    if (oldSessions.length) {
      set(this.#clearSessions, oldSessions);
    }
  };

  effects: Effect[] = [
    this.#retrievePrivateKeyEffect,
    this.#sendPrivateKeyToOtherDeviceEffect,
    this.#autoClearOldSessionsEffect
  ];
}

@labelAtoms
export class DeviceSetupAtoms {
  #deviceKeyTransferAtoms: DeviceKeyTransferAtoms;
  constructor(
    private authAtoms: AuthAtoms,
    private userAtoms: UserAtoms,
    private historyAtoms: HistoryAtoms
  ) {
    this.#deviceKeyTransferAtoms = new DeviceKeyTransferAtoms(
      this.authAtoms,
      this.isDeviceSetup,
      this.#finishDeviceSetup
    );
  }

  /**
   * Device setup info and setters
   */
  isDeviceSetup = atomWithRefresh(async get => {
    const coreClient = await get(initialCoreClientAtom);
    return await coreClient?.isDeviceSetup();
  });

  // Redirect after setup
  redirectAfterSetup = atomWithStorage<string | null>('deviceSetupRedirect', null);

  /** Starts the process. Should fire on device setup page initialization */
  startDeviceSetup = atom(null, async (get, set) => {
    const session = get(this.authAtoms.session);
    const user = get(this.authAtoms.user);
    if (!session || !user) throw new Error('No session or user');
    set(this.#deviceKeyTransferAtoms.start);
  });

  /**
   * Recover the private key using a recovery code
   */
  recoverPrivateKey = atom(null, async (get, set, recoveryCode: string) => {
    try {
      const coreClient = await get(coreClientAtom);
      const publicKey = get(this.userAtoms.current)?.publicKey;
      if (!publicKey) throw new Error('No public key');
      const result = await coreClient.recoverPrivateKey(recoveryCode, publicKey);
      if (!result.success) throw new Error(result.message);
      set(this.#finishDeviceSetup);
    } catch (err) {
      log.error(err);
      set(toastAtom, {
        title: 'There was an error setting up your private key',
        description: (err as Error)?.message
      });
    }
  });

  #finishDeviceSetup = atom(null, async (get, set) => {
    const user = get(this.authAtoms.user);
    const session = get(this.authAtoms.session);
    if (!user || !session) throw new Error('No user or session');

    set(toastAtom, { title: 'Your private key has been set up!' });
    const redirect = get(this.redirectAfterSetup);
    const router = get(this.historyAtoms.router);
    await set(this.isDeviceSetup); // Refresh
    await router?.navigate({ to: redirect ?? '/' });
    // Reset all of the state
    await set(this.#deviceKeyTransferAtoms.clear);
    set(this.redirectAfterSetup, null);
  });

  /**
   * Send private key to another device
   */

  get effects(): Effect[] {
    return [...this.#deviceKeyTransferAtoms.effects];
  }
}
