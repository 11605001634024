import { createRoute, redirect } from '@tanstack/react-router';
import { rootRoute } from './root';
import DeviceSetupPage from 'src/components/pages/DeviceSetupPage';
import { getCurrentUserFromRouteContext } from './common';
import { deviceSetupAtoms } from 'src/state';

export const deviceSetupRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/device-setup',
  beforeLoad: async ({ location, context }) => {
    const user = await getCurrentUserFromRouteContext(context);
    if (!user) {
      throw redirect({
        to: '/login',
        search: location.href && location.href !== '/' ? { redirect: location.href } : {}
      });
    }
    // Already set up, just go back to the index route
    const isDeviceSetup = await context.store.get(deviceSetupAtoms.isDeviceSetup);
    // Already set up, just go back to the index route
    if (isDeviceSetup) {
      throw redirect({
        to: '/'
      });
    }
    // Make sure we start the device set up on load
    await context.store.set(deviceSetupAtoms.startDeviceSetup);
  },
  component: DeviceSetupPage
});
