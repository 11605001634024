import { ParsedLocation, Register } from '@tanstack/react-router';
import { atom } from 'jotai';
import { labelAtoms, Effect } from './utils';

@labelAtoms
export class HistoryAtoms {
  location = atom<ParsedLocation | null>(null);

  pathname = atom(get => get(this.location)?.pathname ?? null);

  router = atom<Register['router'] | null>(null);

  routeMatches = atom(get => {
    const router = get(this.router);
    const location = get(this.location); // Triggers the update
    if (!router || !location) return null;
    return router.matchRoutes(router.latestLocation);
  });

  syncLocation: Effect = (get, set) => {
    const router = get(this.router);
    if (!router) {
      set(this.location, null);
      return;
    }
    let cleaned = false;
    const unlisten = router.history.subscribe(() => {
      // Need to wait one tick to get the latest location
      Promise.resolve().then(() => {
        if (cleaned) return;
        set(this.location, router.latestLocation);
      });
    });
    set(this.location, router.latestLocation);
    return () => {
      if (cleaned) return;
      cleaned = true;
      unlisten();
    };
  };

  effects: Effect[] = [this.syncLocation];
}
