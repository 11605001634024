import { FormApi, FormState } from '@tanstack/react-form';
import { isNotEmpty } from 'axil-utils';
import axios, { AxiosResponse } from 'axios';
import { DataSourceBuilder } from 'daydash-data-structures';
import { atom } from 'jotai';
import { atomWithMutation } from 'jotai-tanstack-query';
import { cloneDeep, isEmpty, get as lget, set as lset } from 'lodash';
import log from 'src/log';
import { z } from 'zod';
import { HistoryAtoms } from './history.atoms';
import { Effect, labelAtoms } from './utils';
import { DataSourceForm } from 'src/components/DataSource/CreateDataSource';

export const dataSourceFormSchema = z.object({
  accessCredentialId: z.string().optional(),
  connectorId: z.string().min(1),
  name: z.string().optional(),
  staticFileId: z.string().optional(),
  // There isn't exactly optional but it will be either defaulted or the connector will throw a validation error on submission
  sections: z.array(z.string()).optional(),
  config: z.any().optional(),
  clientData: z.any().optional() // TODO: Figure out proper types for this
});

export type DataSourceFormValues = z.infer<typeof dataSourceFormSchema>;

export type SubmitBuilderPayload = {
  currentDataSource: {
    connectorId: string;
    accessCredentialId?: string | undefined;
    name?: string | undefined;
    config?: any;
    clientData?: any;
  };
  currentStepId: string | null;
  refresh?: boolean;
};

type Step = 'pick-data-source' | 'success' | string;

export const isFormChild = (
  child: any
): child is { fieldName: string; default: any; value: any } => {
  return Boolean((child as any).fieldName);
};

@labelAtoms
export class DataSourceFormAtoms {
  constructor(private historyAtoms: HistoryAtoms) {}

  dataSourceForm = atom<DataSourceForm | null>(null);

  dataSourceFormState = atom<DataSourceForm['state'] | null>();

  currentDataSourceFormStepId = atom<Step>('pick-data-source');

  resetDataSourceForm = atom(null, (get, set) => {
    set(this.dataSourceForm, null);
    set(this.currentDataSourceFormStepId, 'pick-data-source');
    set(this.dataSourceFormState, null);
    set(this.createdDataSourceId, null);
    set(this.buildResponseSteps, []);
  });

  private syncDataSourceFormState: Effect = (get, set) => {
    const dataSourceForm = get(this.dataSourceForm);
    if (!dataSourceForm) return;
    return dataSourceForm.store.subscribe(() => {
      set(this.dataSourceFormState, dataSourceForm.store.state);
    });
  };

  private autoRefresh: Effect = (get, set) => {
    const dataSourceForm = get(this.dataSourceForm);
    if (!dataSourceForm) return;
    let prevValues: DataSourceFormValues | null = null;
    return dataSourceForm.store.subscribe(() => {
      const values = dataSourceForm.store.state.values;
      if (isEmpty(values)) return; // Tanstack form sets the values briefly to empty during reset. Skip reset updates
      const step = get.peek(this.currentDataSourceFormStep);
      if (!step || step.pending) return;
      const fieldsWithRefreshOnChange = step.content
        .filter(content => 'refreshOnChange' in content && content.refreshOnChange)
        .map(content => ('fieldName' in content ? content.fieldName : null))
        .filter(isNotEmpty);

      if (
        fieldsWithRefreshOnChange.some(
          fieldName => lget(values, fieldName) !== lget(prevValues, fieldName)
        )
      ) {
        set(this.submitCurrentBuilder, true);
      }
      prevValues = values;
    });
  };

  buildResponseSteps = atom<DataSourceBuilder.ConnectorStep[]>([]);

  dataSourceFormSteps = atom<DataSourceBuilder.ConnectorStep[]>(get => {
    return [
      {
        id: 'pick-data-source',
        label: 'Pick Data Source',
        content: [],
        completed: true,
        requiresSubmission: true
      },
      ...get(this.buildResponseSteps),
      {
        id: 'success',
        label: 'Success',
        content: [],
        completed: true,
        requiresSubmission: false,
        nextButtonText: 'Finish'
      }
    ];
  });

  createdDataSourceId = atom<string | null>(null);

  submitBuilderMutation = atomWithMutation<
    AxiosResponse<{
      dataSource: {
        id: string;
        // More data source stuff here but the types slightly differ than Electric
      } | null;
      nextStepId: string;
      steps: DataSourceBuilder.ConnectorStep[];
    }>,
    SubmitBuilderPayload
  >(() => {
    return {
      mutationFn: payload => {
        return axios.post(`/data-source/builder/submit`, payload);
      }
    };
  });

  isSubmittingBuilder = atom(get => {
    return get(this.submitBuilderMutation).isPending;
  });

  finishCreateDataSource = atom(null, async (get, set) => {
    const createdId = get(this.createdDataSourceId);
    if (!createdId) throw new Error('No data source created!');
    const router = get(this.historyAtoms.router);
    router?.navigate({
      to: '/data-source/$id',
      params: { id: createdId }
    });
  });

  currentDataSourceFormStep = atom(get => {
    const currentStepId = get(this.currentDataSourceFormStepId);
    const steps = get(this.dataSourceFormSteps);
    return steps.find(step => step.id === currentStepId);
  });

  submitCurrentBuilder = atom(null, async (get, set, refresh: boolean = false) => {
    const dataSourceForm = get(this.dataSourceForm);
    if (!dataSourceForm) return;
    try {
      const { values } = dataSourceForm.store.state;
      const currentStep = get(this.currentDataSourceFormStep);
      if (!currentStep) throw new Error('Invalid step found!');
      if (currentStep.pending) throw new Error('Cannot submit a pending step!');
      if (!currentStep.requiresSubmission) {
        throw new Error('Should only submit steps that require submission!');
      }
      const result = await get(this.submitBuilderMutation).mutateAsync({
        currentDataSource: values,
        currentStepId: currentStep.id,
        refresh
      });
      set(this.buildResponseSteps, result.data.steps);
      set(this.createdDataSourceId, result.data?.dataSource?.id ?? null);
      // Set values received from the backend and update the form
      if (currentStep.id !== result.data.nextStepId || refresh) {
        const newValues = cloneDeep(values);
        // Now add all of the form defaults of the new step
        result.data.steps.forEach(step => {
          if (step?.pending) return;
          step.content.forEach(child => {
            if (
              isFormChild(child) &&
              (lget(newValues, child.fieldName) == null ||
                // When refreshing, reset values unless it was a refresh on change value itself
                (refresh &&
                  step.id === result.data.nextStepId &&
                  !('refreshOnChange' in child && child.refreshOnChange)))
            ) {
              lset(newValues, child.fieldName, child.default ?? child.value);
            }
          });
        });
        // Reset the form when you switch steps so the submit count stays consistent and default values are merged in
        dataSourceForm.reset();
        dataSourceForm.baseStore.setState(s => ({ ...s, values: newValues }));
        set(this.currentDataSourceFormStepId, result.data.nextStepId);
      }
    } catch (err) {
      // TODO: Set errors based on the response if it is errored out
      log.error(err);
    }
  });

  effects = [this.syncDataSourceFormState, this.autoRefresh];
}
