import { Cfg, CommonRPCMessage, isRPCCall, MessageTarget } from './types';

export default class CommonRpcReceiver<T extends Cfg> {
  constructor(
    private target: MessageTarget,
    private postHeartbeat: () => void,
    private handlers: T
  ) {}

  private cleanupMessageHandler: (() => void) | null = null;

  private heartbeatInterval: NodeJS.Timeout | null = null;

  private initializeHeartbeat = () => {
    this.heartbeatInterval = setInterval(() => {
      this.postHeartbeat();
    }, 50);

    if (typeof window !== 'undefined') {
      window.addEventListener('unload', () => {
        // Add a final disconnect in browser environments to clean things up immediately
        this.target.postMessage('disconnect');
      });
    }
  };

  public destroyed = false;

  public destroy = () => {
    this.cleanupMessageHandler?.();
    if (this.heartbeatInterval) clearInterval(this.heartbeatInterval);
    this.destroyed = true;
  };

  private addHandlerListeners = () => {
    this.cleanupMessageHandler = this.target.addMessageListener(
      async (message: CommonRPCMessage) => {
        if (!isRPCCall(message)) {
          return;
        }
        console.debug('Received RPC call:', message);
        const { id, method, params } = message;
        try {
          const result = await this.handlers[method](...params);
          this.target.postMessage({ id, success: true, result });
        } catch (error) {
          console.error('Error in RPC handler:', error, message);
          this.target.postMessage({ id, success: false, error });
        }
      }
    );
  };

  initialize = () => {
    this.addHandlerListeners();
    this.initializeHeartbeat();
  };

  static createFromMessagePort<T extends Cfg>(port: MessagePort, handlers: T) {
    const target = {
      postMessage: port.postMessage.bind(port),
      addMessageListener: (listener: (message: any) => void) => {
        const handler = (event: MessageEvent) => {
          listener(event.data);
        };
        port.addEventListener('message', handler);
        return () => {
          port.removeEventListener('message', handler);
        };
      }
    };
    const postHeartbeat = () => {
      port.postMessage('heartbeat');
    };
    return new CommonRpcReceiver<T>(target, postHeartbeat, handlers);
  }

  static createFromBroadcastChannel<T extends Cfg>(
    sendChannel: BroadcastChannel,
    responseChannel: BroadcastChannel,
    handlers: T
  ) {
    const target = {
      postMessage: responseChannel.postMessage.bind(responseChannel),
      addMessageListener: (listener: (message: any) => void) => {
        const handler = (event: MessageEvent) => {
          console.log('Received message:', event.data);
          return listener(event.data);
        };
        sendChannel.addEventListener('message', handler);
        return () => {
          sendChannel.removeEventListener('message', handler);
        };
      }
    };
    const postHeartbeat = () => {
      sendChannel.postMessage('heartbeat');
    };
    sendChannel.onmessageerror = ev => {
      console.error('Receiver broadcast channel error:', ev);
    };
    responseChannel.onmessageerror = ev => {
      console.error('Response broadcast channel error:', ev);
    };

    return new CommonRpcReceiver<T>(target, postHeartbeat, handlers);
  }

  static createFromWorker<T extends Cfg>(worker: Worker | DedicatedWorkerGlobalScope, handlers: T) {
    const target = {
      postMessage: worker.postMessage.bind(worker),
      addMessageListener: (listener: (message: any) => void) => {
        const handler = (event: Event) => {
          listener((event as any).data); // Since the message events collapse
        };
        worker.addEventListener('message', handler);
        return () => {
          worker.removeEventListener('message', handler);
        };
      }
    };
    const postHeartbeat = () => {
      worker.postMessage('heartbeat');
    };
    return new CommonRpcReceiver<T>(target, postHeartbeat, handlers);
  }
}
