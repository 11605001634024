import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { getBreakpointSize } from 'src/hooks/styles';
import { BreakPoint, getClerkTheme, getTailwindThemeValues, getVisXTheme } from 'src/theme';
import WebFont from 'webfontloader';
import { UserAtoms } from './user.atoms';
import { Effect, labelAtoms, matchMediaAtom } from './utils';

// --breakpoint-sm: 40rem;
// --breakpoint-md: 48rem;
// --breakpoint-lg: 64rem;
// --breakpoint-xl: 80rem;
// --breakpoint-2xl: 96rem;
@labelAtoms
export class UIAtoms {
  constructor(private userAtoms: UserAtoms) {}

  pageSize = atom<BreakPoint>('md');

  isSmallScreenSize = atom(get => get(this.pageSize) === 'sm');

  private syncPageSize: Effect = (get, set) => {
    const { breakPoints } = get(this.themeValues);
    const sync = () => set(this.pageSize, getBreakpointSize(window.innerWidth, breakPoints));
    sync();
    window.addEventListener('resize', sync);
    return () => window.removeEventListener('resize', sync);
  };

  loadWebFonts = atom(async get => {
    await new Promise<void>(resolve => {
      WebFont.load({
        google: {
          families: ['Open+Sans:ital,wght@0,300..800;1,300..800']
        },
        classes: false,
        active: resolve
      });
    });
    return true;
  });

  webFontsLoaded = unwrap(this.loadWebFonts);

  private darkmodeQuery = matchMediaAtom('(prefers-color-scheme: dark)');

  private userAppearancePreference = atom(get => {
    const currentUser = get(this.userAtoms.current);
    return currentUser?.preferences?.appearance ?? null;
  });

  themeMode = atom(get => {
    const darkmodeQuery = get(this.darkmodeQuery);
    const userAppearance = get(this.userAppearancePreference);
    if (!userAppearance || userAppearance === 'system') {
      return darkmodeQuery ? 'dark' : 'light';
    }
    return userAppearance;
  });

  private syncThemeMode: Effect = (get, set) => {
    document.documentElement.dataset.theme = get(this.themeMode);
  };

  themeValues = atom(get => {
    get(this.themeMode); // the theme mode css variables will be updated when the theme mode is updated. React to it here
    return getTailwindThemeValues();
  });

  visxTheme = atom(get => {
    return getVisXTheme(get(this.themeValues), get(this.themeMode));
  });

  clerkTheme = atom(get => {
    return getClerkTheme(get(this.themeValues), get(this.themeMode));
  });

  effects: Effect[] = [this.syncPageSize, this.syncThemeMode];
}
