import { deferred } from 'axil-utils';
import { v4 as uuid } from 'uuid';
import {
  Cfg,
  Cleanup,
  MessageTarget,
  RPCCall,
  RPCCancelError,
  RPCResult,
  isRPCResult
} from './types';

export default class CommonRpcSender<T extends Cfg> {
  constructor(
    private target: MessageTarget,
    private receiveHeartbeat: (onHeartbeat: () => void) => Cleanup,
    private onDisconnect?: () => void
  ) {}

  public currentCalls = new Map<string, { cancel: () => void }>();

  public disconnected = false;

  private disconnect = () => {
    this.disconnected = true;
    this.onDisconnect?.();
    for (const { cancel } of this.currentCalls.values()) {
      cancel();
    }
  };

  public initiallyConnected = deferred();

  /**
   * Check if the connection is still alive with the target
   */
  initializeConnectionCheck = () => {
    let lastBeat = Date.now();
    const cleanup = this.receiveHeartbeat(() => {
      lastBeat = Date.now();
      this.disconnected = false;
      if (this.initiallyConnected.pending) {
        this.initiallyConnected.resolve();
      }
    });
    // If we haven't heard back from the other side in 8 seconds, we should disconnect
    const interval = setInterval(() => {
      if (Date.now() - lastBeat > 8000) {
        clearInterval(interval);
        cleanup && cleanup();
        this.disconnect();
      }
    }, 50);
  };

  initialize = () => {
    this.initializeConnectionCheck();
  };

  call = async <M extends keyof T, R = ReturnType<T[M]>>(
    method: M,
    ...params: Parameters<T[M]>
  ): Promise<Awaited<R>> => {
    let cleanup: Cleanup | null = null;
    const id = uuid();
    if (typeof method !== 'string') throw new Error('method must be a string');
    const payload: RPCCall = {
      id,
      method,
      params
    };
    await this.initiallyConnected.promise; // Wait for the initial connection before continuing
    try {
      return new Promise<Awaited<R>>((resolve, reject) => {
        const cancel = () => {
          if (cleanup) cleanup();
          this.currentCalls.delete(id);
          reject(new RPCCancelError());
        };
        const respond = (message: RPCResult) => {
          if (!cleanup) throw new Error('cleanup is null');
          this.currentCalls.delete(id);
          cleanup();
          cleanup = null;
          if (message.success) {
            resolve(message.result);
          } else {
            reject(message.error);
          }
        };
        cleanup = this.target.addMessageListener(message => {
          if (!isRPCResult(message)) return;
          if (message?.id !== id) return;
          console.debug('Received RPC result:', message);
          respond(message);
        });
        this.currentCalls.set(id, { cancel });
        console.log('Sending RPC call:', payload);

        this.target.postMessage(payload);
      });
    } catch (err) {
      if (cleanup) (cleanup as any)(); // TS bug where it think its always null
      throw err;
    }
  };

  static createFromMessagePort<T extends Cfg>(port: MessagePort, onDisconnect?: () => void) {
    const target = {
      postMessage: port.postMessage.bind(port),
      addMessageListener: (listener: (message: any) => void) => {
        const handler = (event: MessageEvent) => {
          listener(event.data);
        };
        port.addEventListener('message', handler);
        return () => {
          port.removeEventListener('message', handler);
        };
      }
    };
    const receiveHeartbeat = (onHeartbeat: () => void) => {
      const handleMessage = (event: MessageEvent) => {
        if (event.data === 'heartbeat') {
          onHeartbeat();
        }
      };
      port.addEventListener('message', handleMessage);
      return () => {
        port.removeEventListener('message', handleMessage);
      };
    };
    return new CommonRpcSender<T>(target, receiveHeartbeat, onDisconnect);
  }

  static createFromBroadcastChannels<T extends Cfg>(
    sendChannel: BroadcastChannel,
    responseChannel: BroadcastChannel,
    onDisconnect?: () => void
  ) {
    const target = {
      postMessage: sendChannel.postMessage.bind(sendChannel),
      addMessageListener: (listener: (message: any) => void) => {
        const handler = (event: MessageEvent) => {
          return listener(event.data);
        };
        responseChannel.addEventListener('message', handler);
        return () => {
          responseChannel.removeEventListener('message', handler);
        };
      }
    };
    sendChannel.onmessageerror = ev => {
      console.error('Send broadcast channel error:', ev);
    };
    responseChannel.onmessageerror = ev => {
      console.error('Response broadcast channel error:', ev);
    };
    const receiveHeartbeat = (onHeartbeat: () => void) => {
      const handleMessage = (event: MessageEvent) => {
        if (event.data === 'heartbeat') {
          onHeartbeat();
        }
      };
      sendChannel.addEventListener('message', handleMessage);
      return () => {
        sendChannel.removeEventListener('message', handleMessage);
      };
    };
    return new CommonRpcSender<T>(target, receiveHeartbeat, onDisconnect);
  }

  static createFromWorker<T extends Cfg>(
    worker: Worker | DedicatedWorkerGlobalScope,
    onDisconnect?: () => void
  ) {
    const target = {
      postMessage: worker.postMessage.bind(worker),
      addMessageListener: (listener: (message: any) => void) => {
        const handler = (event: Event) => {
          listener((event as any).data); // Since the message events collapse
        };
        worker.addEventListener('message', handler);
        return () => {
          worker.removeEventListener('message', handler);
        };
      }
    };
    const receiveHeartbeat = (onHeartbeat: () => void) => {
      const handleMessage = (event: Event) => {
        if ((event as any).data === 'heartbeat') {
          onHeartbeat();
        }
      };
      worker.addEventListener('message', handleMessage);
      return () => {
        worker.removeEventListener('message', handleMessage);
      };
    };
    return new CommonRpcSender<T>(target, receiveHeartbeat, onDisconnect);
  }
}
