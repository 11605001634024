import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Connector } from '../../types/service';
import { useAtomValue } from 'jotai';
import { coreClientAtom } from 'src/state';

export function useConnectorById() {
  const { isLoading, data: connectors } = useQuery<Connector[], AxiosError>({
    queryKey: [`/data-source/connector-list`]
  });
  return {
    isLoading,
    connectors: connectors ? Object.fromEntries(connectors.map(c => [c.id, c])) : null
  };
}

export const useAllDataSources = () => {
  const coreClient = useAtomValue(coreClientAtom);
  return useQuery(coreClient.getQueryOptions.getAllDataSources());
};

export const useDataSource = (id: string) => {
  const coreClient = useAtomValue(coreClientAtom);
  return useQuery(coreClient.getQueryOptions.getDataSource(id));
};
